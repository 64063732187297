<section class="terms">
  <h1>Política de Privacidade</h1>

  <div class="container">
    <div class="text">
      <p>
        O Controlador tal como definido na Lei Geral de Proteção de Dados,
        outras leis e disposições de caráter de proteção de dados é:
      </p>
      <address>
        DETELLA RESTAURANTES EMPRESARIAIS LTDA Rua Nayr Leonzio Covolan, nº 976
        – Santa Catarina, Caxias do Sul / RS<br />e-mail: ti@detella.com
      </address>
      <p>
        O Operador tal como definido na Lei Geral de Proteção de Dados, outras
        leis e disposições de caráter de proteção de dados é:
      </p>
      <address>
        YOURWAY DIGITAL LTDA Avenida Therezinha Pauletti Sanvitto, nº 208, sala
        208 – Sanvitto, Caxias do Sul / RS <br />email: contato@yourway.digital
      </address>
      <h2>
        POLÍTICA DE PRIVACIDADE TERMO DE CONSENTIMENTO PARA O TRATAMENTO DE
        DADOS
      </h2>
      <p>
        Este documento visa registrar a manifestação livre, informada e
        inequívoca pela qual o Titular concorda com o tratamento de seus dados
        pessoais para finalidade específica, em conformidade com a Lei nº 13.709
        – Lei Geral de Proteção de Dados Pessoais (LGPD).
      </p>
      <p>
        Na DETELLA RESTAURANTES EMPRESARIAIS LTDA, privacidade e segurança são
        prioridades e nos comprometemos com a transparência do tratamento de
        dados pessoais dos nossos usuários/clientes. Por isso, esta presente
        Política de Privacidade estabelece como é feita a coleta, uso e
        transferência de informações de clientes ou outras pessoas que acessam
        ou usam nosso site.
      </p>
      <p>
        Ao utilizar nossos serviços, você entende que coletaremos e usaremos
        suas informações pessoais nas formas descritas nesta Política, sob as
        normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das
        disposições consumeristas da Lei Federal 8078/1990 e as demais normas do
        ordenamento jurídico brasileiro aplicáveis.
      </p>
      <p>
        Dessa forma, a DETELLA RESTAURANTES EMPRESARIAIS LTDA, doravante
        denominada simplesmente como Detella, inscrita sob o nº CNPJ nº
        93.359.032/0001-69, no papel de Controladora de Dados, obriga-se ao
        disposto na presente Política de Privacidade.
      </p>
      <p>
        O responsável pela proteção de dados é o Controlador: Detella, CNPJ nº ,
        93.359.032/0001-69 com sede na Rua Nayr Leonzio Covolan, nº 976, Caxias
        do Sul / RS, telefone: (54) 3211-1166, e e-mail: ti@detella.com.br
      </p>
      <p>
        O responsável pelo tratamento de dados é o Operador: YOURWAY DIGITAL
        LTDA, CNPJ nº 34.740.210/0001-38, com sede na Avenida Therezinha
        Pauletti Sanvitto, nº 208, sala 208 – Sanvitto, Caxias do Sul / RS,
        telefone: 54 9957-0060, e-mail: contato@yourway.digital. Os dados
        registrados serão armazenados para DETELLA RESTAURANTES EMPRESARIAIS
        LTDA Rua Nayr Leonzio Covolan, nº 976, Bairro Santa Catarina – Caxias do
        Sul/RS Contato: (54) 3211-1166 E-mail: ti@detella.com.br tratamento em
        um servidor na nuvem, de posse do Operador, e seu acesso é feito somente
        com senha, seguindo todas as boas práticas de segurança e
        assecuratórias.
      </p>
      <p>
        A YOURWAY é considerada Operadora nos termos da LGPD e o tratamento de
        dados pessoais será realizado segundo as instruções fornecidas pela
        Detella como Controlador, respeitando os termos da lei.
      </p>
      <p>
        A Detella está comprometida em respeitar e proteger a privacidade de
        seus dados. Caso você envie dados pessoais para nós, tenha a certeza de
        que essas informações estão sendo utilizadas unicamente para manter sua
        relação comercial, parceria ou trabalhista com a Detella ou para uma
        outra das finalidades estabelecidas nesta declaração.
      </p>
      <ul>
        <li>
          <h3>I. DADOS PESSOAIS</h3>
          <p>
            A Controladora fica autorizada a tomar decisões referentes ao
            tratamento e a realizar o tratamento dos seguintes dados pessoais do
            Titular citados abaixo:
          </p>
          <ol>
            <li>
              1. Termo de consentimento para tratamento de dados (permissão);
            </li>
            <li>2. Local onde visualizou a publicação de nossa proposta;</li>
            <li>3. Nome completo;</li>
            <li>4. Nome empresarial;</li>
            <li>5. WhatsApp e endereços de e-mail;</li>
            <li>6. Pesquisa de satisfação do preenchimento do formulário;</li>
          </ol>
          <p>
            Além disso, a Controladora fica autorizada a tomar decisões
            referentes ao tratamento e a realizar o tratamento dos seguintes
            dados inseridos pelo Titular, com a intenção de obter os dados
            curriculares e/ou serviços prestados pelos mesmos.
          </p>
        </li>
        <li>
          <h3>II. FINALIDADES DO TRATAMENTO DOS DADOS</h3>
          <p>
            O tratamento dos dados pessoais, profissionais e comerciais,
            listados neste termo, são coletados de forma a viabilizar a
            prestação de serviços e aprimorar a experiência de uso.
          </p>
        </li>
        <li>
          <h3>III. COMPARTILHAMENTO DE DADOS</h3>
          <p>
            A Controladora fica autorizada a compartilhar os dados pessoais do
            Titular com outros agentes de tratamento de dados, caso seja
            necessário para as finalidades listadas neste termo, observados os
            princípios e as garantias estabelecidas pela Lei nº 13.709.
          </p>
        </li>
        <li>
          <h3>IV. SEGURANÇA DOS DADOS</h3>
          <p>
            A Controladora responsabiliza-se pela manutenção de medidas de
            segurança, técnicas e administrativas aptas a proteger os dados
            pessoais, profissionais e comerciais e de acessos não autorizados e
            de situações acidentais ou ilícitas de destruição, perda, alteração,
            comunicação ou qualquer forma de tratamento inadequado ou ilícito.
            Em conformidade ao art. 48 da Lei nº 13.709, o Controlador
            comunicará ao Titular e à Autoridade Nacional de Proteção de Dados
            (ANPD) a ocorrência de incidente de segurança que possa acarretar
            risco ou dano relevante ao Titular.
          </p>
        </li>
        <li>
          <h3>V. TÉRMINO DO TRATAMENTO DOS DADOS</h3>
          <p>
            Seus dados pessoais coletados pela Detella serão utilizados e
            armazenados durante o tempo necessário para a prestação do serviço
            ou para que as finalidades elencadas na presente Política de
            Privacidade sejam atingidas, considerando os direitos dos titulares
            dos dados e dos controladores.
          </p>
          <p>
            De modo geral, seus dados serão mantidos enquanto a relação entre
            você e a Detella perdurar. Findado o período de armazenamento dos
            dados pessoais, estes serão excluídos de nossas bases de dados ou
            anonimizados, ressalvadas as hipóteses legalmente previstas no
            artigo 16 lei geral de proteção de dados, a saber:
          </p>
          <p>
            I – Cumprimento de obrigação legal ou regulatória pelo controlador;
            II – estudo por órgão de pesquisa, garantida, sempre que possível, a
            anonimização dos dados pessoais; III – transferência a terceiro,
            desde que respeitados os requisitos de tratamento de dados dispostos
            nesta Lei; ou IV – uso exclusivo do controlador, vedado seu acesso
            por terceiro, e desde que anonimizados os dados.
          </p>
          <p>
            Isto é, informações pessoais sobre você que sejam imprescindíveis
            para o cumprimento de determinações legais, judiciais e
            administrativas e/ou para o exercício do direito de defesa em
            processos judiciais e administrativos serão mantidas, a despeito da
            exclusão dos demais dados.
          </p>
          <p>
            O armazenamento de dados coletados pela Detella reflete o nosso
            compromisso com a segurança e privacidade dos seus dados. Empregamos
            medidas e soluções técnicas de proteção aptas a garantir a
            confidencialidade, integridade e inviolabilidade dos seus dados.
            Além disso, também contamos com medidas de segurança apropriadas aos
            riscos e com controle de acesso às informações armazenadas.
          </p>
        </li>
        <li>
          <h3>VI. DIREITOS DO TITULAR</h3>
        </li>
        <p>
          A Detella assegura a seus usuários/clientes seus direitos de titular
          previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma,
          você pode, de maneira gratuita e a qualquer tempo:
        </p>
        <ol>
          <li>
            • Confirmar a existência de tratamento de dados, de maneira
            simplificada ou em formato claro e completo.
          </li>
          <li>
            • Acessar seus dados, podendo solicitá-los em uma cópia legível sob
            forma impressa ou por meio eletrônico, seguro e idôneo.
          </li>
          <li>
            • Corrigir seus dados, ao solicitar a edição, correção ou
            atualização destes.
          </li>
          <li>
            • Limitar seus dados quando desnecessários, excessivos ou tratados
            em desconformidade com a legislação através da anonimização,
            bloqueio ou eliminação.
          </li>
          <li>
            • Solicitar a portabilidade de seus dados, através de um relatório
            de dados cadastrais que trata a seu respeito.
          </li>
          <li>
            • Eliminar seus dados tratados a partir de seu consentimento, exceto
            nos casos previstos em lei.
          </li>
          <li>
            • Revogar seu consentimento, desautorizando o tratamento de seus
            dados.
          </li>
        </ol>
        <li>
          <h3>
            VII. DIREITO DE REVOGAÇÃO DO CONSENTIMENTO E DADOS DE CONTATO PARA A
            SOLICITAÇÃO
          </h3>
          <p>
            Este consentimento poderá ser revogado pelo Titular, a qualquer
            momento, mediante solicitação formalizada via e-mail ou
            correspondência física ao Controlador. E-mail: ti@detella.com.br
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>
