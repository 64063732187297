import { Cliente } from "./cliente.model";

export class NotificacaoCliente {

    id: any = null;
    idNotificacao: number;
    cliente: Cliente | null = null;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}