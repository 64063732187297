import { FilialUsuario } from './filial-usuario.model';
import { Restaurante } from './restaurante.model';

export class Usuario {

    id: number;
    imagemPerfil?: string = '';
    codigo: string = '';
    nome: string = '';
    sobrenome: string = '';
    email: string = '';
    senha: string = '';
    tipo: string = 'N';
    solicitaAlteracao: string = '';
    codigoRecuperacao: string = '';
    playerId: string = '';
    situacao: string = 'A'
    idFilial?: number;
    idCliente?: number;
    idRestaurante?: number;
    filiais: FilialUsuario[] = [];
    restaurantes: Restaurante[] = [];

    [x: string]: any;

    constructor(obj?) {

    }
}
