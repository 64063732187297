<h2 mat-dialog-title>
    Feedback
</h2>
<div mat-dialog-content>
    <div class="feedback-message">
        {{data.feedback}}
    </div>
    <mat-form-field class="page-form-control">
        <textarea matInput rows="6" placeholder="Resposta" [(ngModel)]="resposta" name="resposta"
            [disabled]="data.status === 'R' || auth.user.tipo === 'CL'"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="onNoClick()">Fechar</button>
    <button mat-button (click)="onYesClick()" [disabled]="!resposta.length || data.status === 'R' || auth.user.tipo === 'CL'">Responder</button>
</div>
