<header class="header">
    <nav class="header-nav">
        <button mat-icon-button aria-label="Abrir menu" class="header-menu-toggle" (click)="global.toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <ul class="header-menu" [class.active]="global.menuOpen">

            <mat-form-field class="page-table__search">
                <input matInput [(ngModel)]="search" placeholder="Pesquise aqui">
                <button mat-button *ngIf="search" type="button" matSuffix mat-icon-button aria-label="Limpar"
                    (click)="search = null">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <ng-container *ngIf="auth.user.tipo === 'A'">
                <li class="header-menu__item" *ngFor="let p of pages | filter:search:'name';">
                    <a (click)="goTo(p.route)" mat-button class="header-menu__link">
                        <i [class]="p.icon"></i>
                        <span>{{p.name}}</span>
                    </a>
                </li>
            </ng-container>

            <ng-container *ngIf="auth.user.tipo === 'NUT' || auth.user.tipo === 'C'">
                <li class="header-menu__item" *ngFor="let p of pagesNut | filter:search:'name';">
                    <a (click)="goTo(p.route)" mat-button class="header-menu__link">
                        <i [class]="p.icon"></i>
                        <span>{{p.name}}</span>
                    </a>
                </li>
            </ng-container>

            <ng-container *ngIf="auth.user.tipo === 'CL'">
                <li class="header-menu__item" *ngFor="let p of pagesCli | filter:search:'name';">
                    <a (click)="goTo(p.route)" mat-button class="header-menu__link">
                        <i [class]="p.icon"></i>
                        <span>{{p.name}}</span>
                    </a>
                </li>
            </ng-container>

            <li class="header-menu__item">
                <a (click)="logout()" mat-button color="primary" class="header-menu__link">
                    <i class="fas fa-sign-out-alt"></i>
                    <span>Sair</span>
                </a>
            </li>
        </ul>
        <div class="header-menu-backdrop" (click)="global.toggleMenu()" [class.active]="global.menuOpen"></div>
        <a routerLink="/" class="header-logo">
            <img src="assets/logo.png" alt="Nuprocess">
        </a>

        <div class="right">
            <!-- <ng-container *ngIf="filialUsuario && auth.user.filiais.length && auth.user.tipo === 'A'">
                <button mat-button [matMenuTriggerFor]="filialUserMenu" class="header-user">
                    {{filialUsuario.filial?.nome}}
                </button>
                <mat-menu #filialUserMenu="matMenu">
                    <button mat-menu-item *ngFor="let f of auth.user.filiais" [disabled]="f === filialUsuario"
                        (click)="changeFilialUsuario(f)">{{f.filial.nome}}</button>
                </mat-menu>
            </ng-container> -->

            <ng-container *ngIf="auth.user.tipo === 'C' || auth.user.tipo === 'NUT'">
                <button mat-button [matMenuTriggerFor]="restauranteUserMenu" class="header-user">
                    {{restaurante?.restaurante?.nome}}
                </button>
                <mat-menu #restauranteUserMenu="matMenu">
                    <button mat-menu-item *ngFor="let r of auth.user.restaurantes" [disabled]="r.id === restaurante.id"
                        (click)="changeRestaurante(r)">{{r.restaurante?.nome}}</button>
                </mat-menu>
            </ng-container>
            <button mat-button [matMenuTriggerFor]="userMenu" class="header-user">
                <span>Olá, <b>{{auth.user.nome}} {{auth.user.sobrenome}}</b></span>
                <div class="header-user-avatar">
                    <img [src]="auth.user.imagemPerfil " [alt]="auth.user.nome" *ngIf="auth.user.imagemPerfil ">
                    <img src="assets/image-placeholder.png" [alt]="auth.user.nome" *ngIf="!auth.user.imagemPerfil ">
                </div>
            </button>
            <mat-menu #userMenu="matMenu">
                <!-- <button mat-menu-item [routerLink]="['usuarios/editar', auth.user.id]">Editar perfil</button> -->
                <button mat-menu-item (click)="logout()">Sair</button>
            </mat-menu>
        </div>
    </nav>
</header>
