<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Editar notificação</h1>
        <div class="page-header__buttons">
          <mat-slide-toggle
            (change)="onSituacaoChange($event)"
            [checked]="data.situacao === 'A' ? true : false"
          >
            Situação:
            {{ data.situacao == "A" ? "Ativo" : "Inativo" }}</mat-slide-toggle
          >
          <button mat-stroked-button color="primary" (click)="goBack()">
            VOLTAR
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="submit(form)"
          >
            SALVAR
          </button>
        </div>
      </div>
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Geral">
          <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">
            <h3 class="page-form__title">Dados da notificação</h3>

            <mat-form-field class="page-form-control">
              <input
                matInput
                type="text"
                placeholder="Título"
                [(ngModel)]="data.titulo"
                name="titulo"
                required
              />
            </mat-form-field>

            <mat-form-field class="page-form-control">
              <textarea
                matInput
                rows="6"
                placeholder="Descrição"
                [(ngModel)]="data.descricao"
                name="descricao"
              ></textarea>
            </mat-form-field>

            <mat-form-field class="page-form-control third">
              <mat-label>Escolha uma data inicial</mat-label>
              <input
                matInput
                [matDatepicker]="pickerDataInicial"
                name="pickerDataInicial"
                [(ngModel)]="data.dataInicial"
                readonly
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerDataInicial"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerDataInicial></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="page-form-control third">
              <mat-label>Escolha uma data final</mat-label>
              <input
                matInput
                [matDatepicker]="pickerDataFinal"
                name="pickerDataFinal"
                [(ngModel)]="data.dataFinal"
                readonly
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerDataFinal"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerDataFinal></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="page-form-control third">
              <input
                matInput
                autocomplete="off"
                type="tel"
                placeholder="Horas"
                name="hora"
                [(ngModel)]="horaString"
                mask="Hh:m0"
                required
              />
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab label="Clientes" *ngIf="data.id">
          <app-notificacao-clientes
            [idNotificacao]="data.id"
          ></app-notificacao-clientes>
        </mat-tab>
      </mat-tab-group>
      <hr />
    </div>
  </div>
</section>
