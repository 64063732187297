import { HttpProgressEvent } from "@angular/common/http";
import { PratoService } from "./../../services/class/prato.service";
import { Prato } from "./../../models/prato.model";
import { Subscription } from "rxjs";
import { AuthService } from "./../../services/auth.service";
// Default
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { Location } from "@angular/common";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-pratos-criar",
  templateUrl: "./pratos-criar.component.html",
  styleUrls: ["./pratos-criar.component.scss"],
})
export class PratosCriarComponent implements OnInit {
  data: Prato = new Prato();

  file: File;
  progress: number = 0;

  userSubjectSubscription: Subscription;

  constructor(
    public pratoService: PratoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public location: Location,
    public _global: GlobalService
  ) {}

  ngOnInit() {
    this.buscarFilial();
    this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
      this.router.navigate(["/pratos"])
    );
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  buscarFilial() {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    this.data.filial.id = idFilial;
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Inserindo item...");

    this.pratoService.post(this.data).subscribe(
      (res: any) => {
        this.router.navigate(["/pratos"]).then(() => {
          this.helper.openSnackBar("Item inserido com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present("0%");
    this.submitImages(this.file, `/prato/imagem?id=-99`)
      .then((res: any) => {
        this.data.imagem = res.body;
        this.loadingService.dismiss();
      })
      .catch(() => {
        this.progress = 0;
        this.fileInput.nativeElement.value = "";
        this.loadingService.dismiss();
      });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.pratoService.postFile(file, url, "image").subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        },
        (err) => reject(err)
      );
    });
  }

  removeImage() {
    this.data.imagem = "";
  }

  goBack() {
    this.location.back();
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  onPrincipalChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.principal = "S") : (this.data.principal = "N");
  }
}
