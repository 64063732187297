import { FilialUsuario } from "./../../models/filial-usuario.model";
import { Filial } from "./../../models/filial.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FilialService {
  constructor(public api: ApiService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = '%'
  ): Observable<FiliaisApi> {
    return this.api.get("/filial/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
    });
  }

  getById(id): Observable<any> {
    return this.api.get(`/filial/buscar`, { id: id });
  }

  getSelect(): Observable<any> {
    return this.api.get(`/select/filial/buscar`);
  }

  post(filial: Filial): Observable<any> {
    return this.api.post("/filial/inserir", filial);
  }

  patch(filial: Filial): Observable<any> {
    return this.api.post("/filial/alterar", filial);
  }

  delete(filial: Filial): Observable<any> {
    return this.api.post("/filial/deletar", filial);
  }

  deleteSelected(filiais: Filial[]): Observable<any> {
    return this.api.post("/filial/deletarLista", filiais);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Filial, v2: Filial) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }

  compareFn2(v1: FilialUsuario, v2: FilialUsuario) {
    return v1 && v2 ? v1.id === v2.filial.id : v1 === v2;
  }
}

export interface FiliaisApi {
  filiais: Filial[];
  numeroPaginas: number;
}
