import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RestauranteService } from "./../../services/class/restaurante.service";
import { ClienteService } from "./../../services/class/cliente.service";
import { Restaurante } from "./../../models/restaurante.model";
import { Subscription } from "rxjs";
import { Cliente } from "src/app/models/cliente.model";
import { Component, OnInit, Inject } from "@angular/core";
import { Avaliacao } from "src/app/models/avaliacao.model";
import { NotificacaoCliente } from "src/app/models/notificacao-cliente.model";
import { NotificacaoClienteService } from "src/app/services/class/notificacao-cliente.service";
import { Notificacao } from "src/app/models/notificacao.model";

@Component({
  selector: 'app-dialog-notificacao-clientes',
  templateUrl: './dialog-notificacao-clientes.component.html',
  styleUrls: ['./dialog-notificacao-clientes.component.scss']
})
export class DialogNotificacaoClientesComponent implements OnInit {
  clientes: Cliente[] = [];
  searchClientes: string = "";
  buscarClientesSubscription: Subscription;

  restaurantes: Restaurante[] = [];
  searchRestaurantes: string = "";

  isSubmitting = false;

  notificacaoCliente = new NotificacaoCliente();

  constructor(
    public dialogRef: MatDialogRef<DialogNotificacaoClientesComponent>,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public notificacaoClienteService: NotificacaoClienteService,
    public helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idNotificacao?: string;
    }
  ) { }

  ngOnInit() {
    this.buscarClientes();
  }

  ngOnDestroy() {
    this.buscarClientesSubscription.unsubscribe();
  }

  buscarClientes() {
    this.buscarClientesSubscription = this.clienteService
      .getSelect()
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(f: NgForm): void {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Formulário inválido');
      return;
    }

    const data = {
      ...new NotificacaoCliente(),
      ...this.notificacaoCliente,
      notificacao: {
        ...new Notificacao(),
        id: this.data.idNotificacao
      },
    } as NotificacaoCliente;

    this.isSubmitting = true;

    this.notificacaoClienteService.post(data).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.dialogRef.close(true);
      },
      error: () => (this.isSubmitting = false),

    });
  }
}
