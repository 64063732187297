<section class="login">
    <div class="login-wrapper">
        <div class="left">
            <div class="logo">
                <img src="assets/logo.png" alt="Detella" title="Detella">
            </div>
            <h1 class="login-title">Esqueceu sua<br />senha?</h1>
            <p class="text-left">
                Digite seu endereço de e-mail abaixo e enviaremos uma nova senha para seu usuário.
            </p>
            <form class="login-form" (ngSubmit)="esqueceuSenha(form)" #form="ngForm">
                <mat-form-field class="form-control">
                    <input matInput placeholder="E-mail" type="email" [(ngModel)]="user.email" name="email" required>
                </mat-form-field>

                <button mat-flat-button color="primary" class="btn">
                    Enviar e-mail
                </button>

                <a routerLink="/login" title="Voltar ao login">Voltar ao login</a>
            </form>
        </div>
        <div class="right">
            <span class="dev-yourway">Desenvolvido por <b>YourWay Digital</b></span>
        </div>
    </div>
</section>