import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { Cliente } from "src/app/models/cliente.model";
import { Restaurante } from "src/app/models/restaurante.model";
import { ClienteService } from "src/app/services/class/cliente.service";
import { RestauranteService } from "src/app/services/class/restaurante.service";
import { ChartData } from "chart.js";
import { HelperService } from "src/app/services/helper.service";
import { HomeService } from "src/app/services/class/home.service";
import { Data } from "@angular/router";
import {
  FeedbacksApi,
  FeedbackService,
} from "src/app/services/class/feedback.service";
import { Feedback } from "src/app/models/feedback.model";
import { LoadingService } from "src/app/services/loading.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalFeedbackComponent } from "src/app/components/modal-feedback/modal-feedback.component";
import { Subscription } from "rxjs";
import { DialogConfirmedListComponent } from "src/app/components/dialog-confirmed-list/dialog-confirmed-list.component";
import { CategoriaService } from "src/app/services/class/categoria.service";
import { DialogAvalicaoExcelComponent } from "src/app/components/dialog-avalicao-excel/dialog-avalicao-excel.component";
import * as Chart from "chart.js";

export class HomeObject {
  data: Data;
  checkinPorTipo: any[] = [];
  dias: any[] = [];
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  displayedColumns: string[] = ["position"];

  cliente: Cliente = new Cliente();
  clientes: Cliente[] = [];
  searchClientes: string = "";

  restaurante: Restaurante = new Restaurante();
  restaurantes: Restaurante[] = [];
  searchRestaurantes: string = "";

  feedbacks: Feedback[] = [];

  tipo: string = "%";
  dataInicial: Date;
  dataFinal: Date;

  pieChart?: Chart;

  pages = [
    { name: "Cardápios", icon: "fas fa-list", route: "/cardapios" },
    { name: "Pratos", icon: "fas fa-drumstick-bite", route: "/pratos" },
    // { name: "Empresas", icon: "fas fa-building", route: "/empresas" },
    //{ name: "Filiais", icon: "fas fa-code-branch", route: "/filiais" },
    { name: "Clientes", icon: "fas fa-user-tie", route: "/clientes" },
    { name: "Restaurantes", icon: "fas fa-utensils", route: "/restaurantes" },
    { name: "Notificações", icon: "fas fa-bell", route: "/notificacoes" },
    { name: "Avaliações", icon: "fas fa-star", route: "/avaliacoes" },
    { name: "Feedbacks", icon: "fas fa-comments", route: "/feedbacks" },
    { name: "Usuários", icon: "fas fa-users", route: "/usuarios" },
  ];

  pagesNut = [
    { name: "Feedbacks", icon: "fas fa-comments", route: "/feedbacks" },
  ];

  pagesCli = [
    { name: "Home", icon: "fas fa-home", route: "/" },
    { name: "Feedbacks", icon: "fas fa-comments", route: "/feedbacks" },
    { name: "Restaurantes", icon: "fas fa-utensils", route: "/restaurantes" },
  ];

  data: ChartData = {};
  legend = {
    display: true,
  };

  confirmados: HomeObject[] = [];
  dias: string[] = [];

  buscarClientesSubscription: Subscription;
  buscarRestaurantesSubscription: Subscription;
  buscarGraficoSubscription: Subscription;
  buscarFeedbacksSubscription: Subscription;
  buscarConfirmadosSubscription: Subscription;

  constructor(
    public auth: AuthService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public categoriaService: CategoriaService,
    public helper: HelperService,
    public homeService: HomeService,
    public feedbackService: FeedbackService,
    public loadingService: LoadingService,
    public dialog: MatDialog
  ) {
    if (
      this.auth.user.tipo === "NUT" ||
      this.auth.user.tipo === "C" ||
      this.auth.user.tipo == "CL"
    ) {
      this.cliente.id = this.auth.restaurante.idCliente;
      this.restaurante.id = this.auth.restaurante.restaurante.id;
      this.cliente.filial.id = this.auth.restaurante.idFilial;
    }

    this.dataInicial = this.helper
      .moment()
      .startOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");
    this.dataFinal = this.helper
      .moment()
      .endOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");
    this.auth.userSubject.subscribe((status) => {
      if (status) {
        if (
          this.auth.user.tipo === "NUT" ||
          this.auth.user.tipo === "C" ||
          this.auth.user.tipo == "CL"
        ) {
          this.cliente.id = this.auth.restaurante.idCliente;
          this.cliente.filial.id = this.auth.restaurante.idFilial;
          this.restaurante.id = this.auth.restaurante.restaurante.id;
        }
        this.buscarClientes();
      }
    });
  }

  ngOnInit() {
    this.buscarFeedbacks();
    this.buscarClientes();
    this.buscarGrafico();
  }

  buscarClientes() {
    if (
      this.buscarClientesSubscription &&
      !this.buscarClientesSubscription.closed
    ) {
      this.buscarClientesSubscription.unsubscribe();
    }
    this.buscarClientesSubscription = this.clienteService
      .getSelect()
      .subscribe((res: Cliente[]) => {
        this.clientes = res;
        if (this.cliente.id) {
          this.buscarRestaurantes();
        }
      });
  }

  buscarFeedbacks() {
    if (
      this.buscarFeedbacksSubscription &&
      !this.buscarFeedbacksSubscription.closed
    ) {
      this.buscarFeedbacksSubscription.unsubscribe();
    }
    this.buscarFeedbacksSubscription = this.feedbackService
      .get(
        1,
        8,
        "1",
        "ASC",
        this.cliente.id ? this.cliente.id : "%",
        this.restaurante.id ? this.restaurante.id : "%"
      )
      .subscribe((res: FeedbacksApi) => (this.feedbacks = res.feedbacks));
  }

  buscarRestaurantes() {
    if (
      this.buscarRestaurantesSubscription &&
      !this.buscarRestaurantesSubscription.closed
    ) {
      this.buscarRestaurantesSubscription.unsubscribe();
    }
    this.buscarRestaurantesSubscription = this.restauranteService
      .getSelect(this.cliente.id)
      .subscribe((res: Restaurante[]) => {
        this.restaurantes = res;
        if (this.restaurante.id) {
          this.buscarGrafico();
          this.buscarConfirmados();
          this.buscarFeedbacks();
        }
      });
  }

  buscarGrafico() {
    if (
      this.buscarGraficoSubscription &&
      !this.buscarGraficoSubscription.closed
    ) {
      this.buscarGraficoSubscription.unsubscribe();
    }
    this.buscarGraficoSubscription = this.homeService
      .graficoAvaliacao(
        this.cliente.id ? this.cliente.id : "%",
        this.restaurante.id ? this.restaurante.id : "%",
        this.dataInicial,
        this.dataFinal,
        this.tipo ? this.tipo : "%"
      )
      .subscribe((res) => this.initChart(res));
  }

  buscarConfirmados() {
    if (this.restaurante.id) {
      if (
        this.buscarConfirmadosSubscription &&
        !this.buscarConfirmadosSubscription.closed
      ) {
        this.buscarConfirmadosSubscription.unsubscribe();
      }

      this.dias = [];
      this.displayedColumns = ["position"];
      this.buscarConfirmadosSubscription = this.homeService
        .confirmados(this.dataInicial, this.dataFinal, this.restaurante.id)
        .subscribe((res: any) => {
          const array = res[0].checkinPorTipo.map((obj, index) => {
            res.forEach((o) => {
              const position = this.helper.moment(o.data).format("DD-MM");
              if (index === 0) {
                this.dias.push(position);
                this.displayedColumns.push(position);
              }
              obj[position] = {};
            });
            return obj;
          });
          res.forEach((element) => {
            const data = this.helper.moment(element.data).format("DD-MM");
            element.checkinPorTipo.forEach((checkin, index) => {
              if (array[index].nome === checkin.nome) {
                array[index][data] = {
                  quantidadeConfirmado: checkin.quantidadeConfirmado,
                  confirmados: checkin.confirmados,
                };
              }
            });
          });
          this.confirmados = array;
        });
    }
  }

  onClienteChange(e) {
    if (!e) {
      this.cliente = new Cliente();
      this.restaurante = new Restaurante();
    }
    this.buscarRestaurantes();
    this.buscarGrafico();
    this.buscarFeedbacks();
  }

  onRestauranteChange(e) {
    if (!e) this.restaurante = new Restaurante();
    this.buscarGrafico();
    this.buscarConfirmados();
    this.buscarFeedbacks();
  }

  onDataInicialChange(e) {
    this.dataInicial = this.helper.moment(e).format("YYYY-MM-DD");
    this.buscarGrafico();
    this.buscarConfirmados();
  }

  onDataFinalChange(e) {
    this.dataFinal = this.helper.moment(e).format("YYYY-MM-DD");
    this.buscarGrafico();
    this.buscarConfirmados();
  }

  onTipoChange(e) {
    this.buscarGrafico();
  }

  openFeedback(feedback: Feedback) {
    const dialogRef = this.dialog.open(ModalFeedbackComponent, {
      width: "1024px",
      data: feedback,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.resposta) {
        this.loadingService.present("Respondendo...");
        this.feedbackService.answer(feedback.id, result.resposta).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Feedback respondido com sucesso.");
            this.loadingService.dismiss();
            this.buscarFeedbacks();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  initChart(values: any[]) {
    const labels = values.map((val) => val.label);
    const data = values.map((val) => val.value);

    const chartData = {
      labels,
      datasets: [
        {
          label: "My First Dataset",
          data: data,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(54, 162, 235)",
          ],
          borderWidth: 1,
          fill: false,
        },
      ],
    };

    const ctx = document.getElementById("pieChart") as HTMLCanvasElement;
    console.log(ctx);

    if (this.pieChart) {
      this.pieChart.data.datasets[0].data = data;
      this.pieChart.data.labels = labels;
      this.pieChart.update();
    } else {
      const chart = new Chart(ctx, {
        data: chartData,
        type: "pie",
      });
      this.pieChart = chart;
    }
  }

  openDialog(confirmados: any[]) {
    const dialogRef = this.dialog.open(DialogConfirmedListComponent, {
      width: "900px",
      data: {
        confirmados: confirmados,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  gerarExcel() {
    if (!this.restaurante.id) {
      this.helper.openSnackBar("Selecione um restaurante.");
      return;
    }

    this.loadingService.present("Gerando...");
    this.homeService
      .excel(this.dataInicial, this.dataFinal, this.restaurante.id)
      .subscribe(
        (res) => {
          window.open(res, "_blank");
          this.helper.openSnackBar("Excel gerado com sucesso.");
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
  }

  gerarExcelResumo() {
    if (!this.restaurante.id) {
      this.helper.openSnackBar("Selecione um restaurante.");
      return;
    }

    this.loadingService.present("Gerando...");
    this.homeService
      .excelResumo(this.dataInicial, this.dataFinal, this.restaurante.id)
      .subscribe(
        (res) => {
          window.open(res, "_blank");
          this.helper.openSnackBar("Excel gerado com sucesso.");
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
  }

  gerarFeedbackExcel() {
    this.loadingService.present("Gerando...");
    this.feedbackService.excel(this.cliente.id, this.restaurante.id).subscribe(
      (res) => {
        window.open(res, "_blank");
        this.helper.openSnackBar("Excel gerado com sucesso.");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  gerarFeedbackExcelNota() {
    if (!this.restaurante.id) {
      this.helper.openSnackBar("Selecione um restaurante.");
      return;
    }

    this.loadingService.present("Gerando...");
    this.feedbackService
      .excelNota(
        this.cliente.filial.id,
        this.cliente.id,
        this.restaurante.id,
        this.dataInicial,
        this.dataFinal,
        this.tipo
      )
      .subscribe(
        (res) => {
          window.open(res, "_blank");
          this.helper.openSnackBar("Excel gerado com sucesso.");
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
  }

  gerarAvalicaoCategoriaExcelNota(idAvalicao: number) {
    if (!idAvalicao) {
      this.helper.openSnackBar("Avaliação inválida");
      return;
    }

    if (!this.restaurante.id) {
      this.helper.openSnackBar("Selecione um restaurante.");
      return;
    }

    this.loadingService.present("Gerando...");
    this.categoriaService.excelNota(idAvalicao).subscribe(
      (res) => {
        window.open(res, "_blank");
        this.helper.openSnackBar("Excel gerado com sucesso.");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  openDialogAvalicaoExcel() {
    if (!this.restaurante.id) {
      this.helper.openSnackBar("Selecione um restaurante.");
      return;
    }

    const dialogRef = this.dialog.open(DialogAvalicaoExcelComponent, {
      width: "500px",
      data: {
        idRestaurante: this.restaurante.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.gerarAvalicaoCategoriaExcelNota(result);
      }
    });
  }
}
