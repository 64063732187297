import { PratoPadraoService } from 'src/app/services/class/prato-padrao.service';
import { PratoPadrao } from 'src/app/models/prato-padrao.model';
// default
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { PratosPadraoDiaEditarComponent } from 'src/app/components/pratos-padrao-dia-editar/pratos-padrao-dia-editar.component';
import { PratosPadraoDiaOrderComponent } from 'src/app/components/pratos-padrao-dia-order/pratos-padrao-dia-order.component';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Cardapio } from 'src/app/models/cardapio.model';

@Component({
  selector: 'app-pratos-padrao-dia',
  templateUrl: './pratos-padrao-dia.component.html',
  styleUrls: ['./pratos-padrao-dia.component.scss']
})
export class PratosPadraoDiaComponent implements OnInit {

  @Input('idCadCardapio') idCadCardapio: number;
  @Input('diaSemana') diaSemana: number;
  @Input('idCliente') idCliente: number;
  @Input('idRestaurante') idRestaurante: number;
  @Input('cardapio') cardapio: Cardapio;

  displayedColumns: string[] = ['select', 'imagem', '1', '2', 'actions'];
  // nome, situacao
  data: PratoPadrao[] = [];
  buscarSubscription: Subscription;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string = '';
  filterSubscription: Subscription;

  selection = new SelectionModel<PratoPadrao>(true, []);

  isSorting: boolean = false;

  constructor(
    public global: GlobalService,
    public pratoPadraoService: PratoPadraoService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService
  ) {
    this.pratoPadraoService.active.subscribe(active => {
      if (active) {
        this.buscar();
      }
    })
  }

  ngOnInit() {
    this.buscar();
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscar() {
    this.isLoadingResults = true;
    this.buscarSubscription = this.pratoPadraoService.get(this.idCadCardapio, this.diaSemana)
      .subscribe((res: PratoPadrao[]) => {
        this.data = this.orderList(res);
        this.resultsLength = res.length;
        this.isLoadingResults = false;
      }, e => {
        this.isRateLimitReached = true;
        this.isLoadingResults = false;
      })
  }

  editData(prato: PratoPadrao) {
    const dialogRef = this.dialog.open(PratosPadraoDiaEditarComponent, {
      width: '400px',
      data: prato
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.helper.openSnackBar('Item alterado com sucesso.');
      }
    })
  }

  deletarData(prato: PratoPadrao) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir item',
        description: 'Você realmente quer excluir esse item? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo item...');
        this.pratoPadraoService.delete(prato).subscribe((res: any) => {
          this.helper.openSnackBar('Item removido com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  deletarDatas() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir itens',
        description: 'Você realmente quer excluir esses itens? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.pratoPadraoService.deleteSelected(this.selection.selected).subscribe((res: any) => {
          this.loadingService.present('Excluindo itens');
          this.helper.openSnackBar('Itens removidos com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  filter(e) {
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.pratoPadraoService.get(this.idCadCardapio, this.diaSemana)
        .subscribe((res: PratoPadrao[]) => {
          this.data = res;
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: PratoPadrao): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    const pratos = this.orderList(this.data);
    this.loadingService.present('Ordenando...');
    this.pratoPadraoService.order(pratos).subscribe(res => {
      this.helper.openSnackBar('Item alterado com sucesso.');
      this.loadingService.dismiss();
      this.buscar();
    }, e => this.loadingService.dismiss())
  }

  orderList(list: PratoPadrao[]) {
    list = list.map((item, i) => {
      item.ordem = i + 1;
      return item;
    })
    return list;
  }

  openDialog() {
    const dialogRef = this.dialog.open(PratosPadraoDiaOrderComponent, {
      width: '900px',
      data: {
        pratos: this.data,
        cardapio: this.cardapio,
        diaSemana: this.diaSemana,
        idCliente: this.idCliente,
        idRestaurante: this.idRestaurante,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}
