import { ModalResetPasswordComponent } from "./components/modal-reset-password/modal-reset-password.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// locale
import { LOCALE_ID } from "@angular/core";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localePt);
// components
import { LoadingComponent } from "./components/loading/loading.component";
import { HeaderComponent } from "./components/header/header.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { DialogCopyAvaliacaoComponent } from "./components/dialog-copy-avaliacao/dialog-copy-avaliacao.component";
// pages
import { UsuariosCriarComponent } from "./pages/usuarios-criar/usuarios-criar.component";
import { UsuariosEditarComponent } from "./pages/usuarios-editar/usuarios-editar.component";
import { UsuariosComponent } from "./pages/usuarios/usuarios.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { FiliaisComponent } from "./pages/filiais/filiais.component";
import { FiliaisCriarComponent } from "./pages/filiais-criar/filiais-criar.component";
import { FiliaisEditarComponent } from "./pages/filiais-editar/filiais-editar.component";
import { EmpresasComponent } from "./pages/empresas/empresas.component";
import { EmpresasCriarComponent } from "./pages/empresas-criar/empresas-criar.component";
import { EmpresasEditarComponent } from "./pages/empresas-editar/empresas-editar.component";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { ClientesCriarComponent } from "./pages/clientes-criar/clientes-criar.component";
import { ClientesEditarComponent } from "./pages/clientes-editar/clientes-editar.component";
import { RestaurantesComponent } from "./pages/restaurantes/restaurantes.component";
import { RestaurantesCriarComponent } from "./pages/restaurantes-criar/restaurantes-criar.component";
import { RestaurantesEditarComponent } from "./pages/restaurantes-editar/restaurantes-editar.component";
import { FilterPipe } from "./pipes/filter.pipe";
import { FeedbacksComponent } from "./pages/feedbacks/feedbacks.component";
import { AvaliacoesComponent } from "./pages/avaliacoes/avaliacoes.component";
import { AvaliacoesConfigComponent } from "./pages/avaliacoes-config/avaliacoes-config.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { CardapiosClienteComponent } from "./pages/cardapios-cliente/cardapios-cliente.component";
import { CardapiosComponent } from "./pages/cardapios/cardapios.component";
import { CardapiosCriarComponent } from "./pages/cardapios-criar/cardapios-criar.component";
import { CardapiosEditarComponent } from "./pages/cardapios-editar/cardapios-editar.component";
import { PratosClienteComponent } from "./pages/pratos-cliente/pratos-cliente.component";
import { PratosComponent } from "./pages/pratos/pratos.component";
import { PratosCriarComponent } from "./pages/pratos-criar/pratos-criar.component";
import { PratosEditarComponent } from "./pages/pratos-editar/pratos-editar.component";
// imports
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
// material design
import { getBrazilianPaginatorIntl } from "./brazilian-paginator-intl";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatTreeModule } from "@angular/material/tree";
import { PratosPadraoDiaComponent } from "./pages/pratos-padrao-dia/pratos-padrao-dia.component";
import { PratosPadraoDiaEditarComponent } from "./components/pratos-padrao-dia-editar/pratos-padrao-dia-editar.component";
import { PratosPadraoDiaOrderComponent } from "./components/pratos-padrao-dia-order/pratos-padrao-dia-order.component";
import { AvaliacoesListagemComponent } from "./pages/avaliacoes-listagem/avaliacoes-listagem.component";
import { AvaliacoesCriarComponent } from "./pages/avaliacoes-criar/avaliacoes-criar.component";
import { ModalFeedbackComponent } from "./components/modal-feedback/modal-feedback.component";
import { ModalUserStatusComponent } from "./components/modal-user-status/modal-user-status.component";
import { DialogConfirmedListComponent } from "./components/dialog-confirmed-list/dialog-confirmed-list.component";
import { DialogAvalicaoExcelComponent } from "./components/dialog-avalicao-excel/dialog-avalicao-excel.component";
import { NotificacoesComponent } from "./pages/notificacoes/notificacoes.component";
import { NotificacoesCriarComponent } from "./pages/notificacoes-criar/notificacoes-criar.component";
import { NotificacoesEditarComponent } from "./pages/notificacoes-editar/notificacoes-editar.component";
import { NotificacaoClientesComponent } from "./pages/notificacao-clientes/notificacao-clientes.component";
import { DialogNotificacaoClientesComponent } from "./components/dialog-notificacao-clientes/dialog-notificacao-clientes.component";
import { PrivacyComponent } from "./admin/pages/privacy/privacy.component";
import { TermsComponent } from "./admin/pages/terms/terms.component";
// import { MatMomentDateModule } from '@angular/material-moment-adapter';

const materialModules = [
  MatInputModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatIconModule,
  MatMenuModule,
  MatProgressBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDialogModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTreeModule,
  MatTabsModule,
  MatListModule,
  DragDropModule,
];

@NgModule({
  declarations: [
    AppComponent,
    // components
    HeaderComponent,
    DialogComponent,
    LoadingComponent,
    ModalResetPasswordComponent,
    // pages
    HomeComponent,
    LoginComponent,
    NotFoundComponent,
    UsuariosComponent,
    UsuariosEditarComponent,
    UsuariosCriarComponent,
    FiliaisComponent,
    FiliaisCriarComponent,
    FiliaisEditarComponent,
    EmpresasComponent,
    EmpresasCriarComponent,
    EmpresasEditarComponent,
    ClientesComponent,
    ClientesCriarComponent,
    ClientesEditarComponent,
    RestaurantesComponent,
    RestaurantesCriarComponent,
    RestaurantesEditarComponent,
    FilterPipe,
    FeedbacksComponent,
    AvaliacoesComponent,
    AvaliacoesConfigComponent,
    ResetPasswordComponent,
    DialogCopyAvaliacaoComponent,
    CardapiosClienteComponent,
    CardapiosComponent,
    CardapiosCriarComponent,
    CardapiosEditarComponent,
    PratosClienteComponent,
    PratosComponent,
    PratosCriarComponent,
    PratosEditarComponent,
    PratosPadraoDiaComponent,
    PratosPadraoDiaEditarComponent,
    PratosPadraoDiaOrderComponent,
    AvaliacoesListagemComponent,
    AvaliacoesCriarComponent,
    ModalFeedbackComponent,
    ModalUserStatusComponent,
    DialogConfirmedListComponent,
    DialogAvalicaoExcelComponent,
    NotificacoesComponent,
    NotificacoesCriarComponent,
    NotificacoesEditarComponent,
    NotificacaoClientesComponent,
    DialogNotificacaoClientesComponent,
    PrivacyComponent,
    TermsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
    // material
    ...materialModules,
    // material cdk
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  exports: [FormsModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
