import { Avaliacao } from "./../../models/avaliacao.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class AvaliacaoService {
  constructor(public api: ApiService, public auth: AuthService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idRestaurante: any = "%",
    filtro: string = '%',
  ): Observable<AvaliacoesApi> {
    return this.api.get("/avaliacao/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idRestaurante: idRestaurante,
    });
  }

  getById(id: number): Observable<Avaliacao> {
    return this.api.get(`/avaliacao/buscar`, { id: id });
  }

  post(avaliacao: Avaliacao) {
    return this.api.post(`/avaliacao/inserir`, avaliacao);
  }

  patch(avaliacao: Avaliacao) {
    return this.api.post(`/avaliacao/alterar`, avaliacao);
  }

  delete(avaliacao: Avaliacao): Observable<any> {
    return this.api.post("/avaliacao/deletar", avaliacao);
  }

  deleteSelected(avaliacoes: Avaliacao[]): Observable<any> {
    return this.api.post("/avaliacao/deletarLista", avaliacoes);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  copy(
    idAvaliacaoOrigem: number,
    idClienteDestino,
    idRestauranteDestino,
    nomeDestino: string,
    avaliacao: Avaliacao
  ): Observable<any> {
    const idFilialDestino = this.auth.user.filiais[0].filial.id;
    return this.api.post(`/avaliacao/copiar`, avaliacao, {
      idAvaliacaoOrigem: idAvaliacaoOrigem,
      idFilialDestino: idFilialDestino,
      idClienteDestino: idClienteDestino,
      idRestauranteDestino: idRestauranteDestino,
      nomeDestino: nomeDestino,
    });
  }
}

export interface AvaliacoesApi {
  avaliacoes: Avaliacao[];
  numeroPaginas: number;
}
