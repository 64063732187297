import { Subject } from "rxjs";
import { GlobalService } from "./global.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Usuario } from "../models/usuario.model";
import { FilialUsuario } from "../models/filial-usuario.model";
import { Restaurante } from "../models/restaurante.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  redirectUrl = "";
  isRecuperarSenha: boolean = false;
  token: string;
  user: Usuario = new Usuario();
  filialUsuario: FilialUsuario = new FilialUsuario();
  restaurante: Restaurante = new Restaurante();

  userSubject = new Subject();

  constructor(public http: HttpClient, public global: GlobalService) {}

  login(user) {
    return this.http.post(this.global.apiUrl + "/usuario/autenticar", user);
  }

  getAuthorizationHeader() {
    return new HttpHeaders().append("Authorization", "Basic " + this.token);
  }

  getUserStorage() {
    return JSON.parse(localStorage.getItem("user_detella"));
  }

  getFilialUsuarioStorage() {
    return JSON.parse(localStorage.getItem("filial_detella"));
  }

  getRestauranteStorage() {
    return JSON.parse(localStorage.getItem("restaurante_detella"));
  }

  setToken(token) {
    if (!token) {
      return;
    }

    this.token = token;
    localStorage.setItem("token_detella", this.token);
  }

  setUser(user) {
    if (!user) {
      return;
    }

    localStorage.setItem("user_detella", JSON.stringify(user));
    this.user = this.getUserStorage();
    this.userSubject.next(true);
  }

  setFilialUsuario(filialUsuario) {
    if (!filialUsuario) return;
    localStorage.setItem("filial_detella", JSON.stringify(filialUsuario));
    this.filialUsuario = this.getFilialUsuarioStorage();
    this.userSubject.next(true);
  }

  setRestaurante(restaurante) {
    if (!restaurante) return;

    localStorage.setItem("restaurante_detella", JSON.stringify(restaurante));
    this.restaurante = this.getRestauranteStorage();
    this.userSubject.next(true);
  }

  getUser(user) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.global.apiUrl + "/usuario/buscar?email=" + user.email)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  logout() {
    this.token = undefined;
    this.user = undefined;
    this.filialUsuario = undefined;
    this.restaurante = undefined;
    localStorage.clear();
  }

  isLoggedIn() {
    if (!this.token) {
      if (localStorage.getItem("token_detella")) {
        this.setToken(localStorage.getItem("token_detella"));
        this.user = this.getUserStorage();
      } else {
        return false;
      }
    }

    return true;
  }

  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.global.apiUrl + "/usuario/esqueceuSenha?email=" + user.email)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }
}
