import { RestauranteService } from './../../services/class/restaurante.service';
import { AuthService } from './../../services/auth.service';
import { Cliente } from 'src/app/models/cliente.model';
import { ClienteService, ClientesApi } from './../../services/class/cliente.service';
// default
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Restaurante } from 'src/app/models/restaurante.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-avaliacoes',
  templateUrl: './avaliacoes.component.html',
  styleUrls: ['./avaliacoes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AvaliacoesComponent implements OnInit {

  displayedColumns: string[] = ['1', '2', '3', '4', '5', 'actions'];
  // nome, cnpj, cidade - uf, filial, situacao
  restaurantesColumnsToDisplay = ['nome', 'observacao', 'actions'];

  data: Cliente[] = [];
  expandedElement: Cliente = new Cliente();

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Cliente>(true, []);

  cliente: Cliente = new Cliente();
  clientes: Cliente[] = [];
  searchClientes: string = '';
  buscarClientesSubscription: Subscription;

  restaurante: Restaurante = new Restaurante();
  restaurantes: Restaurante[] = [];
  searchRestaurantes: string = '';
  buscarRestaurantesSubscription: Subscription;

  userSubjectSubscription: Subscription;

  constructor(
    public global: GlobalService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    this.userSubjectSubscription = this.auth.userSubject.subscribe(stats => {
      if (stats) {
        this.buscar();
        this.searchClientes = '';
        this.searchRestaurantes = '';
        this.buscarClientes();
        this.buscarRestaurantes();
      }
    })
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.buscar();
    this.buscarClientes();
    this.buscarRestaurantes();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.clienteService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.cliente.id ? this.cliente.id : '%', this.restaurante.id ? this.restaurante.id : '%', this.search);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.clientes;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data;
      });
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.clienteService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.cliente.id ? this.cliente.id : '%', this.restaurante.id ? this.restaurante.id : '%', e.toLocaleLowerCase())
        .subscribe((res: ClientesApi) => {
          this.data = this.paginator.pageIndex == 0 ? res.clientes : this.data.concat(res.clientes);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  expandedElementChange(element: Cliente) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.restauranteService.getByCliente(element.id, this.restaurante.id ? this.restaurante.id : '%')
      .subscribe((res: Restaurante[]) => this.expandedElement.restaurantes = res)
  }

  buscarClientes() {
    this.buscarClientesSubscription = this.clienteService.getSelect()
      .subscribe((res: Cliente[]) => this.clientes = res);
  }

  buscarRestaurantes() {
    this.buscarRestaurantesSubscription = this.restauranteService.getSelect(this.cliente.id)
      .subscribe((res: Restaurante[]) => this.restaurantes = res);
  }

  onClienteChange(e) {
    if (!e) {
      this.cliente = new Cliente();
      this.restaurante = new Restaurante(); 
    }
    this.buscar();
    this.buscarRestaurantes();
  }

  onRestauranteChange(e) {
    if (!e) this.restaurante = new Restaurante();
    this.buscar();
  }

}
