import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Categoria } from "src/app/models/categoria.model";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class CategoriaService {
  constructor(public api: ApiService) {}

  get(idAvaliacao: number): Observable<any> {
    return this.api.get(`/avaliacaoCategoria/buscar`, {
      idAvaliacao: idAvaliacao,
    });
  }

  getById(id: number): Observable<any> {
    return this.api.get(`/avaliacaoCategoria/buscar`, { id: id });
  }

  post(categoria: Categoria): Observable<any> {
    return this.api.post("/avaliacaoCategoria/inserir", categoria);
  }

  patch(categoria: Categoria): Observable<any> {
    return this.api.post("/avaliacaoCategoria/alterar", categoria);
  }

  delete(categoria: Categoria): Observable<any> {
    return this.api.post("/avaliacaoCategoria/deletar", categoria);
  }

  order(categorias: Categoria[]): Observable<any> {
    return this.api.post("/avaliacaoCategoria/ordenar", categorias);
  }

  excelNota(idAvaliacao: number) {
    return this.api.get("/avaliacaoCategoriaNota/gerarExcel", {
      idAvaliacao: idAvaliacao,
    });
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
