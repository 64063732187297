<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">
          Adicionar usuário
        </h1>
        <div class="page-header__buttons">
          <mat-slide-toggle (change)="onSituacaoChange($event)" [checked]="data.situacao == 'A' ? true : false">
            Situação:
            {{data.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
          <button mat-stroked-button color="primary" routerLink="/usuarios">
            VOLTAR
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">

        <h3 class="page-form__title">
          Dados do usuário
        </h3>

        <mat-form-field class="page-form-control half">
          <input matInput type="usuario" placeholder="Usuário" [(ngModel)]="data.usuario" name="usuario" required>
          <mat-hint>Escreva um alias que você podera usar na hora do login.</mat-hint>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="email" placeholder="E-mail (opcional)" [(ngModel)]="data.email"
            [pattern]="helper.emailRegex" name="email">
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="data.nome" name="nome" required>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="text" placeholder="Sobrenome" [(ngModel)]="data.sobrenome" name="sobrenome" required>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="password" placeholder="Senha" [(ngModel)]="data.senha" name="senha" required>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="password" placeholder="Confirmar senha" [(ngModel)]="data.re_senha" name="re_senha"
            required>
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Observação" [(ngModel)]="data.observacao" name="observacao">
        </mat-form-field>

        <div class="page-form-control">
          <label id="page-radio-group-label">Selecione o tipo do usuário:</label>
          <mat-radio-group aria-labelledby="page-radio-group-label" class="page-radio-group" [(ngModel)]="data.tipo"
            name="tipo" (ngModelChange)="buscarClientes(null)">
            <mat-radio-button class="page-radio-button" name="tipo" value="A" required>
              Administrador
            </mat-radio-button>
            <mat-radio-button class="page-radio-button" name="tipo" value="N" required>
              Normal
            </mat-radio-button>
            <mat-radio-button class="page-radio-button" name="tipo" value="NUT" required>
              Nutricionista
            </mat-radio-button>
            <mat-radio-button class="page-radio-button" name="tipo" value="C" required>
              Coordenador
            </mat-radio-button>
            <mat-radio-button class="page-radio-button" name="tipo" value="CL" required>
              Cliente
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <ng-container *ngIf="data.tipo === 'C' || data.tipo === 'NUT'">
          <mat-form-field class="page-form-control half">
            <mat-label>Cliente</mat-label>
            <mat-select [(ngModel)]="data.idCliente" name="idCliente" (ngModelChange)="onClienteChange($event)">
              <mat-option>
                <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes"
                  placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let c of clientes | filter:searchClientes:'nome'; let i = index;" [value]="c.id">
                {{c.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="data.tipo === 'N' || data.tipo === 'CL'">
          <mat-form-field class="page-form-control third">
            <mat-label>Filial</mat-label>
            <mat-select name="idFilial" [(ngModel)]="data.idFilial" required (ngModelChange)="onFilialChange($event)">
              <mat-option *ngFor="let f of filiais; let i = index;" [value]="f.id" (click)="onFilialChange($event)">
                {{f.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="page-form-control third">
            <mat-label>Cliente</mat-label>
            <mat-select name="idCliente" [(ngModel)]="data.idCliente" (ngModelChange)="onClienteChange($event)" required
              [disabled]="!data.idFilial">
              <mat-option>
                <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes"
                  placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let c of clientes | filter:searchClientes:'nome'; let i = index;" [value]="c.id">
                {{c.nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="page-form-control third">
            <mat-label>Restaurante</mat-label>
            <mat-select name="idRestaurante" [(ngModel)]="data.idRestaurante" required [disabled]="!data.idCliente">
              <mat-option>
                <ngx-mat-select-search [(ngModel)]="searchRestaurantes" name="searchRestaurantes"
                  placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let r of restaurantes | filter:searchRestaurantes:'nome'; let i = index;"
                [value]="r.id">
                <ng-container>{{r.cliente.nome}} - {{r.nome}}</ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>

        </ng-container>

        <hr>

        <button mat-raised-button color="primary" type="submit">
          CONTINUAR
        </button>

      </form>
    </div>
  </div>
</section>
