import { Prato } from "./../../models/prato.model";
import { AuthService } from "./../auth.service";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PratoService {
  constructor(public api: ApiService, public auth: AuthService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = "%"
  ): Observable<PratosApi> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/prato/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idFilial: idFilial,
    });
  }

  getById(id): Observable<any> {
    return this.api.get(`/prato/buscar`, { id: id });
  }

  getSelect(idCliente: number, idRestaurante: number): Observable<any> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/select/prato/buscar", {
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
    });
  }

  post(prato: Prato): Observable<any> {
    return this.api.post("/prato/inserir", prato);
  }

  patch(prato: Prato): Observable<any> {
    return this.api.post("/prato/alterar", prato);
  }

  delete(prato: Prato): Observable<any> {
    return this.api.post("/prato/deletar", prato);
  }

  deleteSelected(pratos: Prato[]): Observable<any> {
    return this.api.post("/prato/deletarLista", pratos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Prato, v2: Prato) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface PratosApi {
  pratos: Prato[];
  numeroPaginas: number;
}
