import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsAdminLoggedInGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.auth.isLoggedIn() && this.auth.user.tipo === 'A') {
      return true;
    } else if (this.auth.isLoggedIn() && this.auth.user.tipo === 'N') {
      this.auth.redirectUrl = state.url;
      this.router.navigate(['/']);
      return false;
    } else {
      this.auth.redirectUrl = state.url;
      this.router.navigate(['/login']);
      return false;
    }

  }
}
