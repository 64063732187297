import { AuthService } from "../auth.service";
import { NotificacaoCliente } from "../../models/notificacao-cliente.model";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificacaoClienteService {
  constructor(public api: ApiService, public auth: AuthService) { }

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = "%",
    idNotificacao: number | null
  ): Observable<NotificacoesClienteApi> {

    return this.api.get("/notificacaoCliente/buscar", {
      filtro,
      numeroPagina,
      registroPorPagina,
      ordenacao,
      sentidoOrdenacao,
      idNotificacao
    });
  }

  getById(id): Observable<any> {
    return this.api.get("/notificacaoCliente/buscar", { id: id });
  }

  post(notificacaoCliente: NotificacaoCliente): Observable<any> {
    return this.api.post("/notificacaoCliente/inserir", notificacaoCliente);
  }

  delete(notificacaoCliente: NotificacaoCliente): Observable<any> {
    return this.api.post("/notificacaoCliente/deletar", notificacaoCliente);
  }

  deleteSelected(notificacaoClientes: NotificacaoCliente[]): Observable<any> {
    return this.api.post("/notificacaoCliente/deletarLista", notificacaoClientes);
  }

  send(
    idNotificacaoCliente: number
  ): Observable<NotificacoesClienteApi> {

    return this.api.get("/notificacaoCliente/enviar", {
      idNotificacaoCliente
    });
  }

  compareFn(v1: NotificacaoCliente, v2: NotificacaoCliente) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface NotificacoesClienteApi {
  notificacoesNotificacaoCliente: NotificacaoCliente[];
  numeroPaginas: number;
}
