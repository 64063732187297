import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class UsuarioRestauranteService {
  constructor(public api: ApiService) {}

  get(idUsuario: number) {
    return this.api.get(`/usuarioRestaurante/buscar`, { idUsuario: idUsuario });
  }

  post(idUsuario: number, idRestaurante: number) {
    return this.api.get(`/usuarioRestaurante/inserir`, {
      idUsuario: idUsuario,
      idRestaurante: idRestaurante,
    });
  }

  delete(idUsuario: number, idRestaurante: number) {
    return this.api.get(`/usuarioRestaurante/deletar`, {
      idUsuario: idUsuario,
      idRestaurante: idRestaurante,
    });
  }
}
