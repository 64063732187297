import { HttpProgressEvent } from "@angular/common/http";
import { NotificacaoService } from "./../../services/class/notificacao.service";
import { Notificacao } from "./../../models/notificacao.model";
import { Subscription } from "rxjs";
import { AuthService } from "./../../services/auth.service";
// Default
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { Location } from "@angular/common";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { GlobalService } from "src/app/services/global.service";
import * as moment from "moment";
import { NotificacaoClienteService, NotificacoesClienteApi } from "src/app/services/class/notificacao-cliente.service";
import { NotificacaoCliente } from "src/app/models/notificacao-cliente.model";

@Component({
  selector: 'app-notificacoes-editar',
  templateUrl: './notificacoes-editar.component.html',
  styleUrls: ['./notificacoes-editar.component.scss']
})
export class NotificacoesEditarComponent implements OnInit {

  horaString: string = '';
  data: Notificacao = new Notificacao();

  file: File;
  progress: number = 0;

  buscarSubscription: Subscription;
  userSubjectSubscription: Subscription;

  displayedColumns: string[] = ['1', 'actions'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  constructor(
    public notificacaoService: NotificacaoService,
    public notificacaoClienteService: NotificacaoClienteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public location: Location,
    public _global: GlobalService
  ) { }

  ngOnInit() {
    this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
      this.router.navigate(["/notificacoes"])
    );
    this.route.params.subscribe((param) => this.buscar(param.id));
  }

  buscar(id: number) {
    this.buscarSubscription = this.notificacaoService
      .getById(id)
      .subscribe((res: Notificacao) => {
        this.data = res
        this.horaString = moment(this.data.horario).format('HHmm');
      });
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.data.horario = moment().set('hour', Number(this.horaString.substring(0, 2))).set('minute', Number(this.horaString.substring(2, 4))).add(-3, 'hour').toDate();

    this.loadingService.present("Inserindo item...");

    this.notificacaoService.patch(this.data).subscribe(
      (res: any) => {
        this.router.navigate(["/notificacoes"]).then(() => {
          this.helper.openSnackBar("Item salvo com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  goBack() {
    this.location.back();
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }



}
