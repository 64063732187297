export class CardapioEspecial {
    
    id: number = null;
    idCadCardapio: number = null;
    idCadCardapioEspecial: number = null;
    diaSemana: number;
    idPrato: number = null;
    nome: string = '';
    observacao: string = '';
    imagem: string = '';
    limitePorcao: string = '';
    ordem: number = 99;
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}