<h1 mat-dialog-title>Olá, selecione as opções abaixo para copiar o item.</h1>
<form (ngSubmit)="onSubmit(form)" #form="ngForm">
  <div mat-dialog-content class="form">
    <mat-form-field class="page-form-control">
      <mat-label>Cliente</mat-label>
      <mat-select name="cliente" [(ngModel)]="notificacaoCliente.cliente" required>
        <mat-option>
          <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes" placeholderLabel="Pesquise aqui"
            noEntriesFoundLabel="Nenhum registro encontrado">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option>Selecionar</mat-option>
        <mat-option *ngFor="
            let c of clientes | filter: searchClientes:'nome';
            let i = index
          " [value]="c">
          {{ c.nome }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" type="button">Cancelar</button>
    <button mat-button type="submit">Confirmar</button>
  </div>
</form>
