import {
  RestauranteService,
  RestaurantesApi,
} from "./../../services/class/restaurante.service";
import {
  ClienteService,
  ClientesApi,
} from "./../../services/class/cliente.service";
import { Cliente } from "./../../models/cliente.model";
import { AuthService } from "./../../services/auth.service";
import {
  FeedbackService,
  FeedbacksApi,
} from "./../../services/class/feedback.service";
import { Feedback } from "./../../models/feedback.model";
// default
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { GlobalService } from "./../../services/global.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { Restaurante } from "src/app/models/restaurante.model";
import { ModalFeedbackComponent } from "src/app/components/modal-feedback/modal-feedback.component";

@Component({
  selector: "app-feedbacks",
  templateUrl: "./feedbacks.component.html",
  styleUrls: ["./feedbacks.component.scss"],
})
export class FeedbacksComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ["select", "1", "2", "3", "4", "5", "actions"];
  // nome, email, dataHora
  data: Feedback[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Feedback>(true, []);

  idFilial: number = 0;
  idCliente: number = 0;
  clientes: Cliente[] = [];
  searchClientes: string = "";
  buscarClientesSubscription: Subscription;
  idRestaurante: number = 0;
  restaurantes: Restaurante[] = [];
  searchRestaurantes: string = "";
  buscarRestaurantesSubscription: Subscription;
  status: string = "P";

  userSubjectSubscription: Subscription;

  constructor(
    public global: GlobalService,
    public feedbackService: FeedbackService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService
  ) {
    if (
      this.auth.user.tipo === "NUT" ||
      this.auth.user.tipo === "C" ||
      this.auth.user.tipo == "CL"
    ) {
      this.idCliente = this.auth.restaurante.idCliente;
      this.idRestaurante = this.auth.restaurante.restaurante.id;
      this.idFilial = this.auth.restaurante.idFilial;
      this.displayedColumns = ["select", "1", "2", "5", "actions"];
    }
  }

  ngOnInit() {
    this.userSubjectSubscription = this.auth.userSubject.subscribe((stats) => {
      if (stats) {
        if (
          this.auth.user.tipo === "NUT" ||
          this.auth.user.tipo === "C" ||
          this.auth.user.tipo == "CL"
        ) {
          this.idCliente = this.auth.restaurante.idCliente;
          this.idRestaurante = this.auth.restaurante.restaurante.id;
          this.idFilial = this.auth.restaurante.idFilial;
        }

        this.searchClientes = "";
        this.searchRestaurantes = "";
        this.buscar();
        this.buscarClientes();
        this.buscarRestaurantes();
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.buscar();
    this.buscarClientes();
    this.buscarRestaurantes();
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
    this.buscarClientesSubscription.unsubscribe();
    this.buscarRestaurantesSubscription.unsubscribe();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.feedbackService.get(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction.toLocaleUpperCase(),
            this.idCliente ? this.idCliente : "%",
            this.idRestaurante ? this.idRestaurante : "%",
            this.status,
            this.search
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.feedbacks;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.data = data;
      });
  }

  openFeedback(feedback: Feedback) {
    const dialogRef = this.dialog.open(ModalFeedbackComponent, {
      width: "1024px",
      data: feedback,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.resposta) {
        this.loadingService.present("Respondendo...");
        this.feedbackService.answer(feedback.id, result.resposta).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Feedback respondido com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.feedbackService
        .get(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction.toLocaleUpperCase(),
          this.idCliente ? this.idCliente : "%",
          this.idRestaurante ? this.idRestaurante : "%",
          this.status,
          e.toLocaleLowerCase()
        )
        .subscribe((res: FeedbacksApi) => {
          this.data =
            this.paginator.pageIndex == 0
              ? res.feedbacks
              : this.data.concat(res.feedbacks);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = "";
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }

  buscarClientes() {
    this.buscarClientesSubscription = this.clienteService
      .getSelect()
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  buscarRestaurantes() {
    this.buscarRestaurantesSubscription = this.restauranteService
      .getSelect(this.idCliente)
      .subscribe((res: Restaurante[]) => (this.restaurantes = res));
  }

  onClienteChange(e) {
    if (!e) {
      this.idCliente = null;
      this.idRestaurante = null;
    }
    this.paginator.pageIndex = 0;
    this.buscar();
    this.buscarRestaurantes();
  }

  onRestauranteChange(e) {
    if (!e) this.idRestaurante = null;
    this.paginator.pageIndex = 0;
    this.buscar();
  }

  onStatusChange(e) {
    this.paginator.pageIndex = 0;
    this.buscar();
  }

  downloadExcel() {
    this.feedbackService
      .excel(
        this.idCliente ? this.idCliente : "%",
        this.idRestaurante ? this.idRestaurante : "%",
        this.status
      )
      .subscribe((res: string) => window.open(res));
  }
}
