import { Restaurante } from "./restaurante.model";
import { Cliente } from "./cliente.model";
import { Filial } from "./filial.model";

export class Cardapio {
  id: number;
  nome: string = "";
  observacao: string = "";
  dataInicial: Date;
  dataFinal: Date;
  filial: Filial = new Filial();
  cliente: Cliente = new Cliente();
  restaurante: Restaurante = new Restaurante();
  segunda: string = "N";
  terca: string = "N";
  quarta: string = "N";
  quinta: string = "N";
  sexta: string = "N";
  sabado: string = "N";
  domingo: string = "N";
  tipo: string = "P";
  imagem: string = "";
  [x: string]: any;

  constructor(obj?) {
    Object.assign(this);
  }
}
