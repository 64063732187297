import { CardapioService } from "./../../services/class/cardapio.service";
import { Cardapio } from "./../../models/cardapio.model";
import { Subscription } from "rxjs";
import { AuthService } from "./../../services/auth.service";
// Default
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { DiaCadastroCardapio } from "src/app/models/dia-cadastro-cardapio.model";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Location } from "@angular/common";
import { GlobalService } from "src/app/services/global.service";
import { HttpProgressEvent } from "@angular/common/http";

@Component({
  selector: "app-cardapios-criar",
  templateUrl: "./cardapios-criar.component.html",
  styleUrls: ["./cardapios-criar.component.scss"],
})
export class CardapiosCriarComponent implements OnInit {
  data: Cardapio = new Cardapio();

  userSubjectSubscription: Subscription;

  file: File;
  progress: number = 0;

  isTipoValid: boolean = true;

  constructor(
    public cardapioService: CardapioService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public location: Location,
    public _global: GlobalService
  ) {}

  ngOnInit() {
    this.buscarFilial();
    this.route.queryParams.subscribe((query) => {
      this.data.cliente.id = query.idCliente;
      this.data.restaurante.id = query.idRestaurante;
    });
    this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
      this.router.navigate(["/cardapios"])
    );
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  buscarFilial() {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    this.data.filial.id = idFilial;
  }

  onDiaChange(ev: MatCheckboxChange, index: number) {
    const dia = this.data.dias[index];
    ev.checked ? (dia.situacao = "A") : (dia.situacao = "I");
  }

  submit(f: NgForm) {
    this.isTipoValid = true;

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    if (!this.data.tipo) {
      this.isTipoValid = false;
      return;
    }

    this.loadingService.present("Inserindo item...");

    this.cardapioService.post(this.data).subscribe(
      (res: any) => {
        this.router.navigate(["/cardapios/editar", res]).then(() => {
          this.helper.openSnackBar("Item alterado com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  onDomingoChange(event: MatCheckboxChange) {
    event.checked ? (this.data.domingo = "S") : (this.data.domingo = "N");
  }

  onSegundaChange(event: MatCheckboxChange) {
    event.checked ? (this.data.segunda = "S") : (this.data.segunda = "N");
  }

  onTercaChange(event: MatCheckboxChange) {
    event.checked ? (this.data.terca = "S") : (this.data.terca = "N");
  }

  onQuartaChange(event: MatCheckboxChange) {
    event.checked ? (this.data.quarta = "S") : (this.data.quarta = "N");
  }

  onQuintaChange(event: MatCheckboxChange) {
    event.checked ? (this.data.quinta = "S") : (this.data.quinta = "N");
  }

  onSextaChange(event: MatCheckboxChange) {
    event.checked ? (this.data.sexta = "S") : (this.data.sexta = "N");
  }

  onSabadoChange(event: MatCheckboxChange) {
    event.checked ? (this.data.sabado = "S") : (this.data.sabado = "N");
  }

  goBack() {
    this.location.back();
  }

  onTipoChange(ev) {
    if (ev === "E") {
      this.data.domingo = "N";
      this.data.segunda = "N";
      this.data.terca = "N";
      this.data.quarta = "N";
      this.data.quinta = "N";
      this.data.sexta = "N";
      this.data.sabado = "N";
    }
  }

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present("0%");
    this.submitImages(this.file, `/cadastroCardapio/imagem?id=-99`)
      .then((res: any) => {
        this.data.imagem = res.body;
        this.loadingService.dismiss();
      })
      .catch(() => {
        this.progress = 0;
        this.fileInput.nativeElement.value = "";
        this.loadingService.dismiss();
      });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.cardapioService.postFile(file, url, "image").subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        },
        (err) => reject(err)
      );
    });
  }

  removeImage() {
    this.data.imagem = null;
  }
}
