<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Pratos
                </h1>
            </div>

            <div class="page-table">
                <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                    <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                        Não foi possível acessar os dados dessa listagem, aguarde um pouco e tente novamente.
                    </div>
                </div>

                <div class="page-table-filters">
                    <mat-form-field class="page-form-control quad">
                        <mat-label>Cliente</mat-label>
                        <mat-select name="cliente" [(ngModel)]="cliente" (ngModelChange)="onClienteChange($event)">
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes"
                                    placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option>Selecionar</mat-option>
                            <mat-option *ngFor="let c of clientes | filter:searchClientes:'nome'; let i = index;"
                                [value]="c">
                                {{c.nome}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="page-form-control quad">
                        <mat-label>Restaurante</mat-label>
                        <mat-select name="restaurante" [(ngModel)]="restaurante"
                            (ngModelChange)="onRestauranteChange($event)" [disabled]="!cliente.id">
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="searchRestaurantes" name="searchRestaurantes"
                                    placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option>Selecionar</mat-option>
                            <mat-option
                                *ngFor="let r of restaurantes | filter:searchRestaurantes:'nome'; let i = index;"
                                [value]="r">
                                <ng-container *ngIf="cliente.id">{{r.nome}}</ng-container>
                                <ng-container *ngIf="!cliente.id">{{r.cliente.nome}} - {{r.nome}}</ng-container>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="page-table__search page-form-control half">
                        <input matInput [(ngModel)]="search" (ngModelChange)="filter($event)"
                            placeholder="Pesquise aqui">
                        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Limpar"
                            (click)="clearFilter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="page-table-container">
                    <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
                        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        <div class="rate-limit-reached">
                            Nenhum registro encontrado
                        </div>
                    </div>

                    <table mat-table multiTemplateDataRows [dataSource]="data" matSort matSortActive="0"
                        matSortDirection="asc">

                        <ng-container matColumnDef="1">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Nome
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                        </ng-container>

                        <ng-container matColumnDef="2">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                CNPJ
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.cnpj | mask: '00.000.000/0000-00'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="3">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Cidade
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.cidade}} - {{row.uf}}</td>
                        </ng-container>

                        <ng-container matColumnDef="4">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Filial
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.filial?.nome}}</td>
                        </ng-container>

                        <ng-container matColumnDef="5">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Situação</th>
                            <td mat-cell *matCellDef="let row">{{row.situacao == 'A' ? 'Ativo' : 'Inativo'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <mat-icon>expand_more</mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="page-element-detail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                    <!-- restaurantes -->
                                    <table mat-table [dataSource]="element.restaurantes" class="expanded-table">

                                        <ng-container matColumnDef="nome">
                                            <th mat-header-cell *matHeaderCellDef>Nome</th>
                                            <td mat-cell *matCellDef="let row"> {{row.nome}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="observacao">
                                            <th mat-header-cell *matHeaderCellDef>Observacao</th>
                                            <td mat-cell *matCellDef="let row"> {{row.observacao}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let row">
                                                <button mat-icon-button mat-button matTooltip="Ver pratos"
                                                    aria-label="Ver pratos" color="primary"
                                                    (click)="goToPratos(row)">
                                                    <mat-icon>local_pizza</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="restaurantesColumnsToDisplay">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: restaurantesColumnsToDisplay;">
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="page-element-row"
                            [class.page-expanded-row]="expandedElement === element"
                            (click)="expandedElementChange(element)">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="page-detail-row"></tr>
                    </table>
                    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[15, 25, 40, 50]" [pageSize]="15">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</section>