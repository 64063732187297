import { FilialUsuario } from "./../../models/filial-usuario.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FilialUsuarioService {
  constructor(public api: ApiService) {}

  get(id: number): Observable<FilialUsuario[]> {
    return this.api.get("/filialUsuario/buscar", {
      idUsuario: id,
    });
  }

  post(idUsuario: number, idFilial: number): Observable<any> {
    return this.api.get("/filialUsuario/inserir", {
      idUsuario: idUsuario,
      idFilial: idFilial,
    });
  }

  patch(filiais: FilialUsuario[]): Observable<any> {
    return this.api.post("/filialUsuario/alterar", filiais);
  }

  delete(idUsuario: number, idFilial: number): Observable<any> {
    return this.api.get("/filialUsuario/deletar", {
      idUsuario: idUsuario,
      idFilial: idFilial,
    });
  }
}
