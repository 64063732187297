<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Usuários
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="warn" [disabled]="!selection.selected.length"
                        (click)="deletarDatas()">
                        DELETAR ITENS
                    </button>
                    <button mat-raised-button color="primary" routerLink="/usuarios/adicionar">
                        ADICIONAR
                    </button>
                </div>
            </div>

            <div class="page-table-filters">
                <mat-form-field class="page-form-control">
                    <mat-label>Cliente</mat-label>
                    <mat-select name="cliente" [(ngModel)]="cliente" (ngModelChange)="onClienteChange($event)"
                        [compareWith]="clienteService.compareFn" [disabled]="auth.user.tipo === 'NUT'">
                        <mat-option>
                            <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes"
                                placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option>Selecionar</mat-option>
                        <mat-option *ngFor="let c of clientes | filter:searchClientes:'nome'; let i = index;"
                            [value]="c">
                            {{c.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="page-form-control">
                    <mat-label>Restaurante</mat-label>
                    <mat-select name="restaurante" [(ngModel)]="restaurante"
                        (ngModelChange)="onRestauranteChange($event)" [compareWith]="restauranteService.compareFn"
                        [disabled]="auth.user.tipo === 'NUT' || !cliente.id">
                        <mat-option>
                            <ngx-mat-select-search [(ngModel)]="searchRestaurantes" name="searchRestaurantes"
                                placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option>Selecionar</mat-option>
                        <mat-option *ngFor="let r of restaurantes | filter:searchRestaurantes:'nome'; let i = index;"
                            [value]="r">
                            <ng-container *ngIf="cliente.id">{{r.nome}}</ng-container>
                            <ng-container *ngIf="!cliente.id">{{r.cliente.nome}} - {{r.nome}}</ng-container>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="page-form-control">
                    <mat-label>Tipo</mat-label>
                    <mat-select name="tipo" [(ngModel)]="tipo" (ngModelChange)="onTipoChange($event)">
                        <mat-option value="%">Selecionar</mat-option>
                        <mat-option value="N">Normal</mat-option>
                        <mat-option value="A">Administrador</mat-option>
                        <mat-option value="NUT">Nutricionista</mat-option>
                        <mat-option value="C">Coordenador</mat-option>
                        <mat-option value="CL">Cliente</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="page-form-control">
                    <mat-label>Status</mat-label>
                    <mat-select name="status" [(ngModel)]="status" (ngModelChange)="onStatusChange($event)">
                        <mat-option value="%">Selecionar</mat-option>
                        <mat-option value="A">Aprovado</mat-option>
                        <mat-option value="R">Reprovado</mat-option>
                        <mat-option value="P">Pendente</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="page-table-filters">

            </div>

            <div class="page-table">
                <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                    <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                        Não foi possível acessar os dados dessa listagem, aguarde um pouco e tente novamente.
                    </div>
                </div>

                <mat-form-field class="page-table__search">
                    <input matInput [(ngModel)]="search" (ngModelChange)="filter($event)" placeholder="Pesquise aqui">
                    <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Limpar"
                        (click)="clearFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <div class="page-table-container">
                    <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
                        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        <div class="rate-limit-reached">
                            Nenhum registro encontrado
                        </div>
                    </div>

                    <table mat-table [dataSource]="data" matSort matSortActive="0" matSortDirection="asc">

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="1">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Nome
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.nome}} {{row.sobrenome}}</td>
                        </ng-container>

                        <ng-container matColumnDef="2">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
                            <td mat-cell *matCellDef="let row">{{row.email}}</td>
                        </ng-container>

                        <ng-container matColumnDef="3">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Observação</th>
                            <td mat-cell *matCellDef="let row">{{row.observacao}}</td>
                        </ng-container>

                        <mat-option value="N">Normal</mat-option>
                        <mat-option value="A">Administrador</mat-option>
                        <mat-option value="NUT">Nutricionista</mat-option>
                        <mat-option value="C">Coordenador</mat-option>
                        <mat-option value="CL">Cliente</mat-option>

                        <ng-container matColumnDef="4">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
                            <td mat-cell *matCellDef="let row">{{row.tipo == 'N' ? 'Normal' :
                                row.tipo == 'A' ? 'Administrador' :
                                row.tipo == 'NUT' ? 'Nutricionista' :
                                row.tipo == 'C' ? 'Coordenador' :
                                row.tipo == 'CL' ? 'Cliente' : 'Desconhecido'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-slide-toggle [(ngModel)]="situacao" [labelPosition]="'before'"
                                    (ngModelChange)="onSlideChange($event)">
                                    Situação: {{situacao ? 'Ativo' : 'Inativo'}}
                                </mat-slide-toggle>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button mat-button matTooltip="Editar" aria-label="Editar"
                                    color="primary" [routerLink]="['/usuarios/editar', row.id]">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-icon-button mat-button matTooltip="Excluir" aria-label="Excluir"
                                    color="warn" (click)="deletarData(row)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <ng-container *ngIf="row.status === 'P'">
                                    <button mat-icon-button mat-button matTooltip="Aprovar" aria-label="Aprovar"
                                        color="primary" (click)="changeUserStatusDialog(row)">
                                        <mat-icon>check_circle_outline</mat-icon>
                                    </button>
                                    <button mat-icon-button mat-button matTooltip="Reprovar" aria-label="Reprovar"
                                        color="warn" (click)="changeStatus(row.id)">
                                        <mat-icon>remove_circle_outline</mat-icon>
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[15, 25, 40, 50]" [pageSize]="15">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</section>