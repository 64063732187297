<h1 mat-dialog-title>Cardápios especiais</h1>
<div style="margin: 16px 0;">
    <mat-form-field>
        <mat-label>Cardápios </mat-label>
        <mat-select name="prato" [(ngModel)]="prato" required>
            <mat-option>
                <ngx-mat-select-search [(ngModel)]="searchPratos" name="searchPratos" placeholderLabel="Pesquise aqui"
                    noEntriesFoundLabel="Nenhum registro encontrado">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let c of cardapios | filter:searchPratos:'nome';" [value]="c">
                {{c.nome}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-icon-button [attr.aria-label]="'Adicionar'" type="button" [disabled]="!prato.id"
        (click)="adicionarPratosEspecialDia()">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="page-table">
        <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                Não foi possível acessar os dados dessa listagem, aguarde um pouco e tente novamente.
            </div>
        </div>

        <mat-form-field class="page-table__search">
            <input matInput [(ngModel)]="search" (ngModelChange)="filter($event)" placeholder="Pesquise aqui">
            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Limpar" (click)="clearFilter()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <div class="page-table-container">
            <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <div class="rate-limit-reached">
                    Nenhum registro encontrado
                </div>
            </div>

            <table mat-table [dataSource]="data" matSort matSortActive="0" matSortDirection="asc">

                <ng-container matColumnDef="imagem">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Imagem
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <img [src]="global.imageUrl+row.imagem" [alt]="row.nome" class="page-table-image" *ngIf="row.imagem">
                        <img src="assets/image-placeholder.png" [alt]="row.nome" class="page-table-image"
                            *ngIf="!row.imagem">
                    </td>
                </ng-container>

                <ng-container matColumnDef="1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nome
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                </ng-container>

                <ng-container matColumnDef="2">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Observação
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.observacao}}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button mat-button matTooltip="Excluir" aria-label="Excluir" color="warn"
                            (click)="deletarData(row)" type="button">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="resultsLength" [pageSizeOptions]="[15, 25, 40, 50]" [pageSize]="15">
            </mat-paginator>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="onYesClick()">Confirmar</button>
</div>