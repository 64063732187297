<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar empresa
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/empresas">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados da empresa
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput type="text" placeholder="Nome" [(ngModel)]="data.nome" name="nome" required>
                </mat-form-field>

                <mat-slide-toggle (change)="onSituacaoChange($event)" [checked]="data.situacao === 'A' ? true : false">
                    Situação: {{data.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>