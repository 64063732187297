<section class="page">
  <div class="container">
    <div class="page-header">
      <h1 class="page-title">Seja bem-vindo, {{ auth.user.nome }}</h1>
      <div class="page-header__buttons">
        <button
          mat-raised-button
          [matMenuTriggerFor]="menuExcel"
          aria-label="Excel"
          class="btn-excel"
          *ngIf="auth.user.tipo != 'CL'"
        >
          <i class="fas fa-file-excel"></i>
          <span>GERAR EXCEL</span>
        </button>
        <mat-menu #menuExcel="matMenu">
          <button mat-menu-item (click)="gerarExcel()">
            <span>Check-in Completo</span>
          </button>
          <button mat-menu-item (click)="gerarExcelResumo()">
            <span>Check-in Resumo</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="home-filters" *ngIf="auth.user.tipo != 'CL'">
      <mat-form-field appearance="fill">
        <mat-label>Cliente</mat-label>
        <mat-select
          name="cliente"
          [(ngModel)]="cliente"
          (ngModelChange)="onClienteChange($event)"
          [disabled]="
            auth.user.tipo === 'NUT' ||
            auth.user.tipo === 'C' ||
            auth.user.tipo === 'CL'
          "
          [compareWith]="clienteService.compareFn"
        >
          <mat-option>
            <ngx-mat-select-search
              [(ngModel)]="searchClientes"
              name="searchClientes"
              placeholderLabel="Pesquise aqui"
              noEntriesFoundLabel="Nenhum registro encontrado"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option>Selecionar</mat-option>
          <mat-option
            *ngFor="
              let c of clientes | filter : searchClientes : 'nome';
              let i = index
            "
            [value]="c"
          >
            {{ c.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Restaurante</mat-label>
        <mat-select
          name="restaurante"
          [(ngModel)]="restaurante"
          (ngModelChange)="onRestauranteChange($event)"
          [disabled]="
            !cliente.id ||
            auth.user.tipo === 'NUT' ||
            auth.user.tipo === 'C' ||
            auth.user.tipo === 'CL'
          "
          [compareWith]="restauranteService.compareFn"
        >
          <mat-option>
            <ngx-mat-select-search
              [(ngModel)]="searchRestaurantes"
              name="searchRestaurantes"
              placeholderLabel="Pesquise aqui"
              noEntriesFoundLabel="Nenhum registro encontrado"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let r of restaurantes | filter : searchRestaurantes : 'nome';
              let i = index
            "
            [value]="r"
          >
            <ng-container *ngIf="cliente.id">{{ r.nome }}</ng-container>
            <ng-container *ngIf="!cliente.id"
              >{{ r.cliente.nome }} - {{ r.nome }}</ng-container
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Data Inicial</mat-label>
        <input
          matInput
          [max]="dataFinal"
          [(ngModel)]="dataInicial"
          [matDatepicker]="dataInicialRef"
          (ngModelChange)="onDataInicialChange($event)"
          disabled
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dataInicialRef"
        ></mat-datepicker-toggle>
        <mat-datepicker #dataInicialRef disabled="false"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Data Final</mat-label>
        <input
          matInput
          [min]="dataInicial"
          [max]="helper.moment()"
          [(ngModel)]="dataFinal"
          [matDatepicker]="dataFinalRef"
          (ngModelChange)="onDataFinalChange($event)"
          disabled
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dataFinalRef"
        ></mat-datepicker-toggle>
        <mat-datepicker #dataFinalRef disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="home-table mat-elevation-z8" *ngIf="auth.user.tipo != 'CL'">
      <table mat-table [dataSource]="confirmados">
        <ng-container matColumnDef="position" sticky>
          <th mat-header-cell *matHeaderCellDef>Checkins</th>
          <td mat-cell *matCellDef="let element">{{ element.nome }}</td>
        </ng-container>

        <ng-container *ngFor="let d of dias" [matColumnDef]="d">
          <th mat-header-cell *matHeaderCellDef>
            {{ d }} ({{
              helper.moment(d, "DD-MM").format("ddd").toLocaleUpperCase()
            }})
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element[d].quantidadeConfirmado }}
            <button
              mat-icon-button
              (click)="openDialog(element[d].confirmados)"
              *ngIf="element[d].confirmados.length"
            >
              <mat-icon>list</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div class="home-grid">
      <div class="col home-grid-item">
        <div class="home-grid-item__header">
          <div class="home-grid-item__title">
            <h2>Avaliação</h2>
            <div>
              <button
                mat-raised-button
                class="btn-excel"
                [matMenuTriggerFor]="menuExcelAvalicaoGeral"
              >
                <i class="fas fa-file-excel"></i>
                <span>GERAR EXCEL</span>
              </button>
              <mat-menu #menuExcelAvalicaoGeral="matMenu">
                <button mat-menu-item (click)="gerarFeedbackExcelNota()">
                  <span>Geral</span>
                </button>
                <button mat-menu-item (click)="openDialogAvalicaoExcel()">
                  <span>Por categoria</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div
            class="d-flex d-flex__row d-flex__alignCenter d-flex__spaceBetween"
          >
            <mat-form-field appearance="fill">
              <mat-label>Tipo</mat-label>
              <mat-select
                name="tipo"
                [(ngModel)]="tipo"
                (ngModelChange)="onTipoChange($event)"
              >
                <mat-option>Selecionar</mat-option>
                <mat-option value="A">Administrativo</mat-option>
                <mat-option value="P">Produção</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- <ngx-chartjs
          [data]="data"
          type="pie"
          [legend]="legend"
          width="160"
          height="160"
        ></ngx-chartjs> -->
        <canvas id="pieChart" width="160" height="160"></canvas>
      </div>
      <div class="col home-grid-item">
        <div class="home-grid-item__header">
          <h2>Páginas</h2>
        </div>
        <mat-list>
          <ng-container *ngIf="auth.user.tipo === 'A'">
            <mat-list-item *ngFor="let p of pages">
              <div mat-line>
                <button mat-button [routerLink]="[p.route]">
                  <i [class]="p.icon"></i>
                  <span>{{ p.name }}</span>
                </button>
              </div>
            </mat-list-item>
          </ng-container>
          <ng-container *ngIf="auth.user.tipo === 'NUT'">
            <mat-list-item *ngFor="let p of pagesNut">
              <div mat-line>
                <button mat-button>
                  <i [class]="p.icon"></i>
                  <span>{{ p.name }}</span>
                </button>
              </div>
            </mat-list-item>
          </ng-container>
          <ng-container *ngIf="auth.user.tipo === 'CL'">
            <mat-list-item *ngFor="let p of pagesCli">
              <div mat-line>
                <button mat-button>
                  <i [class]="p.icon"></i>
                  <span>{{ p.name }}</span>
                </button>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
      <div class="col home-grid-item">
        <div class="home-grid-item__header">
          <div class="home-grid-item__title">
            <h2>Caderno de Sugestões</h2>
            <div>
              <button
                mat-raised-button
                class="btn-excel"
                (click)="gerarFeedbackExcel()"
              >
                <i class="fas fa-file-excel"></i>
                <span>GERAR EXCEL</span>
              </button>
            </div>
          </div>
        </div>
        <mat-list>
          <ng-container *ngFor="let f of feedbacks; let i = index">
            <mat-list-item>
              <mat-icon mat-list-icon color="primary"
                >sentiment_satisfied_alt</mat-icon
              >
              <!-- <mat-icon mat-list-icon color="warn">sentiment_very_dissatisfied</mat-icon> -->
              <div mat-line *ngIf="!cliente.id">
                <span class="c-tag">{{ f.cliente.nome }}</span>
              </div>
              <div mat-line style="padding-right: 50px">{{ f.feedback }}</div>
              <div mat-line>
                <b>{{ f.nome }}</b> -
                {{ helper.moment(f.dataHora).format("DD/MM HH:mm") }}
              </div>
              <button mat-icon-button color="primary" (click)="openFeedback(f)">
                <mat-icon>chat</mat-icon>
              </button>
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </div>
    </div>
  </div>

  <!-- <div class="home-boxes">
            <ng-container *ngIf="auth.user.tipo === 'A'">
                <button class="home-box" *ngFor="let p of pages;" mat-stroked-button [routerLink]="p.route">
                    {{p.name}}
                </button>
            </ng-container>
            <ng-container *ngIf="auth.user.tipo === 'NUT'">
                <button class="home-box" *ngFor="let p of pagesNut;" mat-stroked-button [routerLink]="p.route">
                    {{p.name}}
                </button>
            </ng-container>
        </div> -->
</section>
