<h2 mat-dialog-title>
    Aprovar
</h2>
<div mat-dialog-content>
    <div class="form">
        <mat-form-field class="page-form-control">
            <mat-label>Filial</mat-label>
            <mat-select name="idFilial" [(ngModel)]="idFilial" required (ngModelChange)="onFilialChange($event)">
                <mat-option *ngFor="let f of filiais; let i = index;" [value]="f.id" (click)="onFilialChange($event)">
                    {{f.nome}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="page-form-control">
            <mat-label>Cliente</mat-label>
            <mat-select name="idCliente" [(ngModel)]="idCliente" (ngModelChange)="onClienteChange($event)" [disabled]="!idFilial">
                <mat-option>
                    <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes"
                        placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let c of clientes | filter:searchClientes:'nome'; let i = index;" [value]="c.id">
                    {{c.nome}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="page-form-control">
            <mat-label>Restaurante</mat-label>
            <mat-select name="idRestaurante" [(ngModel)]="idRestaurante" required [disabled]="!idCliente">
                <mat-option>
                    <ngx-mat-select-search [(ngModel)]="searchRestaurantes" name="searchRestaurantes"
                        placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let r of restaurantes | filter:searchRestaurantes:'nome'; let i = index;"
                    [value]="r.id">
                    <ng-container>{{r.cliente.nome}} - {{r.nome}}</ng-container>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="onNoClick()">Fechar</button>
    <button mat-button (click)="onYesClick()" color="primary">Aprovar</button>
</div>