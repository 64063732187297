export class PratoPadrao {

    id: number;
    idCadCardapio: number;
    diaSemana: number;
    idPrato: number;
    nome: string = '';
    observacao: string = '';
    imagem: string = '';
    limitePorcao: string = '';
    ordem: number = 99;
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}