import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(public api: ApiService, public auth: AuthService) {}

  graficoAvaliacao(
    idCliente: any,
    idRestaurante: any,
    dataInicial: any,
    dataFinal: any,
    tipoPessoa: string
  ) {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get(`/home/avaliacao/grafico`, {
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      tipoPessoa: tipoPessoa,
    });
  }

  confirmados(dataInicial: any, dataFinal: any, idRestaurante: any = "%") {
    return this.api.get(`/home/confirmado/buscar`, {
      idRestaurante: idRestaurante,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }

  excelResumo(dataInicial: any, dataFinal: any, idRestaurante: any = "%") {
    return this.api.get(`/home/confirmado/excelResumo`, {
      idRestaurante: idRestaurante,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }

  excel(dataInicial: any, dataFinal: any, idRestaurante: any = "%") {
    return this.api.get(`/home/confirmado/excel`, {
      idRestaurante: idRestaurante,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    });
  }
}
