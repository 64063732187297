<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Cardápios</h1>
        <div class="page-header__buttons">
          <button mat-stroked-button color="warn" [disabled]="!selection.selected.length" (click)="deletarDatas()">
            DELETAR ITENS
          </button>
          <button mat-raised-button color="primary" (click)="adicionarCardapio()">
            ADICIONAR
          </button>
        </div>
      </div>

      <div class="page-table-filters">
        <mat-form-field class="page-form-control quad">
          <mat-label>Tipo</mat-label>
          <mat-select name="tipo" [(ngModel)]="tipo" (ngModelChange)="onTipoChange($event)">
            <mat-option value="P">Padrão</mat-option>
            <mat-option value="E">Especial</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="page-table">
        <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="rate-limit-reached" *ngIf="isRateLimitReached">
            Não foi possível acessar os dados dessa listagem, aguarde um pouco e
            tente novamente.
          </div>
        </div>

        <mat-form-field class="page-table__search">
          <input matInput [(ngModel)]="search" (ngModelChange)="filter($event)" placeholder="Pesquise aqui" />
          <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Limpar" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <div class="page-table-container">
          <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="rate-limit-reached">Nenhum registro encontrado</div>
          </div>

          <table mat-table [dataSource]="data" matSort matSortActive="0" matSortDirection="asc">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
              <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
            </ng-container>

            <ng-container matColumnDef="2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Observação
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.observacao | slice: 0:128
                }}{{ row.observacao?.length >= 128 ? "..." : "" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Data Inicial
              </th>
              <td mat-cell *matCellDef="let row">
                {{ helper.moment(row.dataInicial).format("DD/MM/YYYY") }}
              </td>
            </ng-container>

            <ng-container matColumnDef="4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Data Final
              </th>
              <td mat-cell *matCellDef="let row">
                {{ helper.moment(row.dataFinal).format("DD/MM/YYYY") }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <a mat-icon-button mat-button matTooltip="Editar" aria-label="Editar" color="primary"
                  [routerLink]="['/cardapios/editar', row.id]">
                  <mat-icon>create</mat-icon>
                </a>
                <button mat-icon-button mat-button matTooltip="Excluir" aria-label="Excluir" color="warn"
                  (click)="deletarData(row)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [length]="resultsLength" [pageSizeOptions]="[15, 25, 40, 50]" [pageSize]="15">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</section>