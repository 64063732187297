import { Avaliacao } from 'src/app/models/avaliacao.model';
// Default
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { AvaliacaoService } from 'src/app/services/class/avaliacao.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-avaliacoes-criar',
  templateUrl: './avaliacoes-criar.component.html',
  styleUrls: ['./avaliacoes-criar.component.scss']
})
export class AvaliacoesCriarComponent implements OnInit {

  data: Avaliacao = new Avaliacao();

  constructor(
    public avalicaoService: AvaliacaoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location
  ) {
    this.route.queryParams.subscribe(query => this.data.restaurante.id = query.idRestaurante);
  }

  ngOnInit() {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.avalicaoService.post(this.data)
      .subscribe(res => {
        this.router.navigate(['/avaliacoes/configuracoes', res])
          .then(() => {
            this.helper.openSnackBar('Item inserido com sucesso.');
            this.loadingService.dismiss();
          })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  goBack() {
    this.location.back();
  }

}
