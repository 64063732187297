<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Editar prato</h1>
        <div class="page-header__buttons">
          <mat-slide-toggle
            (change)="onSituacaoChange($event)"
            [checked]="data.situacao === 'A' ? true : false"
          >
            Situação:
            {{ data.situacao == "A" ? "Ativo" : "Inativo" }}</mat-slide-toggle
          >
          <button mat-stroked-button color="primary" (click)="goBack()">
            VOLTAR
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">
        <h3 class="page-form__title">Dados do prato</h3>

        <mat-form-field class="page-form-control">
          <input
            matInput
            type="text"
            placeholder="Nome"
            [(ngModel)]="data.nome"
            name="nome"
            required
          />
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <textarea
            matInput
            rows="6"
            placeholder="Observação"
            [(ngModel)]="data.observacao"
            name="observacao"
          ></textarea>
        </mat-form-field>

        <hr />

        <h3 class="page-form__title">Imagem (400x400)</h3>

        <div class="page-form-control page-form-image">
          <img
            [src]="global.imageUrl + data.imagem"
            alt="Imagem"
            *ngIf="data.imagem"
          />
          <img
            src="assets/image-placeholder.png"
            alt="Imagem"
            *ngIf="!data.imagem"
          />
          <button type="button" mat-button (click)="fileInput.click()">
            Escolher arquivo
          </button>
          <input
            hidden
            (change)="onFileSelected($event.target.files)"
            #fileInput
            type="file"
            id="imagem"
            accept="image/*"
          />
          <button
            class="page-form-image__button"
            (click)="removeImage()"
            type="button"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>

        <hr />

        <mat-slide-toggle
          (change)="onPrincipalChange($event)"
          [checked]="data.principal === 'S' ? true : false"
        >
          Principal:
          {{ data.principal == "S" ? "Sim" : "Não" }}</mat-slide-toggle
        >

        <hr />

        <button mat-raised-button color="primary" type="submit">SALVAR</button>
      </form>
    </div>
  </div>
</section>
