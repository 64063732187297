import { AuthService } from './../../services/auth.service';
import { FilialService, FiliaisApi } from './../../services/class/filial.service';
import { FilialUsuario } from './../../models/filial-usuario.model';
import { Usuario } from './../../models/usuario.model';
import { UsuarioService } from './../../services/class/usuario.service';

// Default
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Cliente } from 'src/app/models/cliente.model';
import { ClienteService } from 'src/app/services/class/cliente.service';
import { RestauranteService } from 'src/app/services/class/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante.model';
import { Filial } from 'src/app/models/filial.model';

@Component({
  selector: 'app-usuarios-criar',
  templateUrl: './usuarios-criar.component.html',
  styleUrls: ['./usuarios-criar.component.scss']
})
export class UsuariosCriarComponent implements OnInit {

  data: Usuario = new Usuario();
  userSubjectSubscription: Subscription;

  filiais: any[] = [];

  searchClientes: string = '';
  clientes: Cliente[] = [];

  searchRestaurantes: string = '';
  restaurantes: Restaurante[] = [];

  constructor(
    public usuarioService: UsuarioService,
    public filialService: FilialService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.buscarFiliais();
    this.userSubjectSubscription = this.auth.userSubject.subscribe(o => this.router.navigate(['/usuarios']));
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  submit(f: NgForm) {

    if (this.data.idFilial == null) {
      this.data.idFilial = this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    }

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    if (this.data.senha !== this.data.re_senha) {
      this.helper.openSnackBar('As senhas precisam ser iguais.');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.usuarioService.post(this.data)
      .subscribe(res => {
        this.router.navigate(['/usuarios/editar', res]).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  buscarFiliais() {
    this.filialService.getSelect()
      .subscribe((res: Filial[]) => this.filiais = res);
  }

  buscarClientes(idFilial: number) {
    this.clienteService.getSelect(idFilial)
      .subscribe((res: Cliente[]) => this.clientes = res);
  }

  buscarRestaurantes(idCliente: number) {
    this.restauranteService.getSelect(idCliente)
      .subscribe((res: Restaurante[]) => this.restaurantes = res);
  }

  onFilialChange(ev) {
    this.buscarClientes(this.data.idFilial);
  }

  onClienteChange(ev) {
    this.buscarRestaurantes(this.data.idCliente);
  }

}
