<h1 mat-dialog-title>Olá, selecione as opções abaixo para copiar o item.</h1>
<form (ngSubmit)="onDoneClick(form)" #form="ngForm">
  <div mat-dialog-content class="form">
    <mat-form-field class="page-form-control">
      <mat-label>Cliente</mat-label>
      <mat-select name="cliente" [(ngModel)]="data.idCliente" (ngModelChange)="onClienteChange($event)" required>
        <mat-option>
          <ngx-mat-select-search [(ngModel)]="searchClientes" name="searchClientes" placeholderLabel="Pesquise aqui"
            noEntriesFoundLabel="Nenhum registro encontrado">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option>Selecionar</mat-option>
        <mat-option *ngFor="
            let c of clientes | filter: searchClientes:'nome';
            let i = index
          " [value]="c.id">
          {{ c.nome }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="page-form-control">
      <mat-label>Restaurante</mat-label>
      <mat-select name="restaurante" [(ngModel)]="data.idRestaurante" required>
        <mat-option>
          <ngx-mat-select-search [(ngModel)]="searchRestaurantes" name="searchRestaurantes"
            placeholderLabel="Pesquise aqui" noEntriesFoundLabel="Nenhum registro encontrado">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option>Selecionar</mat-option>
        <mat-option *ngFor="
            let r of restaurantes | filter: searchRestaurantes:'nome';
            let i = index
          " [value]="r.id">
          <ng-container *ngIf="data.idCliente">{{ r.nome }}</ng-container>
          <ng-container *ngIf="!data.idCliente">{{ r.cliente.nome }} - {{ r.nome }}</ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="page-form-control">
      <input matInput type="text" placeholder="Nome destino" [(ngModel)]="data.nomeDestino" name="nomeDestino"
        required />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" type="button">Cancelar</button>
    <button mat-button type="submit">Confirmar</button>
  </div>
</form>
