import { AuthService } from "./../auth.service";
import { Feedback } from "./../../models/feedback.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  constructor(public api: ApiService, public auth: AuthService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idCliente: any = "%",
    idRestaurante: any = "%",
    status: any = "%",
    filtro: string = "%"
  ): Observable<FeedbacksApi> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/feedback/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
      status: status,
    });
  }

  delete(feedback: Feedback): Observable<any> {
    return this.api.post("/feedback/deletar", feedback);
  }

  deleteSelected(feedbacks: Feedback[]): Observable<any> {
    return this.api.post("/feedback/deletarLista", feedbacks);
  }

  excel(idCliente: any = "%", idRestaurante: any = "%", status: any = "%") {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/feedback/excel", {
      idFilial: idFilial,
      idCliente: idCliente ? idCliente : "%",
      idRestaurante: idRestaurante ? idRestaurante : "%",
      status: status,
    });
  }

  answer(idFeedback: number, resposta: string) {
    const idUsuarioResposta = this.auth.user.id;
    return this.api.get("/feedback/responder", {
      idFeedback: idFeedback,
      resposta: resposta,
      idUsuarioResposta: idUsuarioResposta,
    });
  }

  excelNota(
    idFilial: number,
    idCliente: number,
    idRestaurante: number,
    dataInicial: Date,
    dataFinal: Date,
    tipoPessoa: string = "%"
  ) {
    return this.api.get("/feedbackNota/gerarExcel", {
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      tipoPessoa: tipoPessoa,
    });
  }
}

export interface FeedbacksApi {
  feedbacks: Feedback[];
  numeroPaginas: number;
}
