<section class="login">
    <div class="login-wrapper">
        <div class="left">
            <div class="logo">
                <img src="assets/logo.png" alt="Nuprocess" title="Nuprocess">
            </div>
            <h1 class="login-title">Seja bem-vindo!</h1>
            <form class="login-form" (ngSubmit)="login(form)" #form="ngForm">
                <mat-form-field class="form-control">
                    <input matInput placeholder="E-mail" type="email" name="email" [(ngModel)]="user.email" required>
                </mat-form-field>

                <mat-form-field class="form-control">
                    <input matInput placeholder="Senha" type="password" name="password" [(ngModel)]="user.senha"
                        required>
                </mat-form-field>

                <div class="login-form-row">
                    <a routerLink="/esqueceu-senha">Esqueceu a senha?</a>
                </div>

                <button mat-flat-button color="primary" class="btn">
                    Entrar
                </button>
            </form>
        </div>
        <div class="right">
            <span class="dev-yourway">Desenvolvido por <b>YourWay Digital</b></span>
        </div>
    </div>
</section>