import { Restaurante } from './restaurante.model';
import { Filial } from './filial.model';

export class Cliente {

    id: any = null;
    nome: string;
    cnpj: number;
    uf: string;
    cidade: string;
    filial: Filial = new Filial();
    restaurantes: Restaurante[] = [];
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}