import { AuthService } from "./../auth.service";
import { Restaurante } from "./../../models/restaurante.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RestauranteService {
  constructor(public api: ApiService, public auth: AuthService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idCliente: any = "%",    
    filtro: string = "%"
  ): Observable<RestaurantesApi> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/restaurante/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idFilial: idFilial,
      idCliente: idCliente,
    });
  }

  getById(id): Observable<any> {
    return this.api.get(`/restaurante/buscar`, { id: id });
  }

  getSelect(idCliente: any = "%"): Observable<any> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get(`/select/restaurante/buscar`, {
      idFilial: idFilial,
      idCliente: idCliente,
    });
  }

  getByCliente(idCliente: number, idRestaurante: any = "%"): Observable<any> {
    return this.api.get("/restaurante/buscar/cliente", {
      idCliente: idCliente,
      idRestaurante: idRestaurante,
    });
  }

  post(restaurante: Restaurante): Observable<any> {
    return this.api.post("/restaurante/inserir", restaurante);
  }

  patch(restaurante: Restaurante): Observable<any> {
    return this.api.post("/restaurante/alterar", restaurante);
  }

  delete(restaurante: Restaurante): Observable<any> {
    return this.api.post("/restaurante/deletar", restaurante);
  }

  deleteSelected(restaurantes: Restaurante[]): Observable<any> {
    return this.api.post("/restaurante/deletarLista", restaurantes);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Restaurante, v2: Restaurante) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface RestaurantesApi {
  restaurantes: Restaurante[];
  numeroPaginas: number;
}
