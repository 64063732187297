import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RestauranteService } from "./../../services/class/restaurante.service";
import { ClienteService } from "./../../services/class/cliente.service";
import { Restaurante } from "./../../models/restaurante.model";
import { Subscription } from "rxjs";
import { Cliente } from "src/app/models/cliente.model";
import { Component, OnInit, Inject } from "@angular/core";
import { Avaliacao } from "src/app/models/avaliacao.model";

class DialogData {
  idCliente: number;
  idRestaurante: number;
  nomeDestino: string;
  constructor() {}
}

@Component({
  selector: "app-dialog-copy-avaliacao",
  templateUrl: "./dialog-copy-avaliacao.component.html",
  styleUrls: ["./dialog-copy-avaliacao.component.scss"],
})
export class DialogCopyAvaliacaoComponent implements OnInit {
  clientes: Cliente[] = [];
  searchClientes: string = "";
  buscarClientesSubscription: Subscription;

  restaurantes: Restaurante[] = [];
  searchRestaurantes: string = "";
  buscarRestaurantesSubscription: Subscription;

  data: DialogData = new DialogData();

  constructor(
    public dialogRef: MatDialogRef<DialogCopyAvaliacaoComponent>,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.buscarClientes();
    this.buscarRestaurantes();
  }

  ngOnDestroy() {
    this.buscarClientesSubscription.unsubscribe();
    this.buscarRestaurantesSubscription.unsubscribe();
  }

  buscarClientes() {
    this.buscarClientesSubscription = this.clienteService
      .getSelect()
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  buscarRestaurantes() {
    this.buscarRestaurantesSubscription = this.restauranteService
      .getSelect(this.data.idCliente)
      .subscribe((res: Restaurante[]) => (this.restaurantes = res));
  }

  onClienteChange(e) {
    this.buscarRestaurantes();
  }

  onDoneClick(f: NgForm): void {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos obrigatórios, por favor.");
      return;
    }
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
