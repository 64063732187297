import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/models/cliente.model';
import { Filial } from 'src/app/models/filial.model';
import { Restaurante } from 'src/app/models/restaurante.model';
import { Usuario } from 'src/app/models/usuario.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClienteService } from 'src/app/services/class/cliente.service';
import { FilialService } from 'src/app/services/class/filial.service';
import { RestauranteService } from 'src/app/services/class/restaurante.service';

@Component({
  selector: 'app-modal-user-status',
  templateUrl: './modal-user-status.component.html',
  styleUrls: ['./modal-user-status.component.scss']
})
export class ModalUserStatusComponent implements OnInit {

  idFilial: number;
  filiais: Filial[] = [];

  idCliente: number;
  searchClientes: string = '';
  clientes: Cliente[] = [];

  idRestaurante: number;
  searchRestaurantes: string = '';
  restaurantes: Restaurante[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalUserStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Usuario,
    public filialService: FilialService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public auth: AuthService
  ) {
  }

  ngOnInit() {
    this.buscarFiliais();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close({
      idRestaurante: this.idRestaurante
    });
  }

  buscarFiliais() {
    this.filialService.getSelect()
      .subscribe((res: Filial[]) => this.filiais = res);
  }

  buscarClientes(idFilial: number) {
    this.clienteService.getSelect(idFilial)
      .subscribe((res: Cliente[]) => this.clientes = res);
  }

  buscarRestaurantes(idCliente: number) {
    this.restauranteService.getSelect(idCliente)
      .subscribe((res: Restaurante[]) => this.restaurantes = res);
  }

  onFilialChange(ev) {
    this.buscarClientes(this.idFilial);
  }

  onClienteChange(ev) {
    this.buscarRestaurantes(this.idCliente);
  }

}
