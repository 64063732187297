import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "./../../services/auth.service";
import {
  CardapioService,
  CardapiosApi,
} from "./../../services/class/cardapio.service";
// default
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { GlobalService } from "./../../services/global.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { Cardapio } from "src/app/models/cardapio.model";
import { Location } from "@angular/common";

export interface QueryCardapio {
  idCliente: number;
  idRestaurante: number;
  page: number;
  tipo: string;
}

@Component({
  selector: "app-cardapios",
  templateUrl: "./cardapios.component.html",
  styleUrls: ["./cardapios.component.scss"],
})
export class CardapiosComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ["select", "1", "2", "3", "4", "actions"];
  // nome, observacao, data inicial, data final
  data: Cardapio[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string = "";
  filterSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Cardapio>(true, []);

  userSubjectSubscription: Subscription;

  query: QueryCardapio;

  tipo: string = "P";

  constructor(
    public global: GlobalService,
    public cardapioService: CardapioService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.userSubjectSubscription = this.auth.userSubject.subscribe((stats) => {
      if (stats) {
        this.buscar();
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.route.queryParams.subscribe((query: QueryCardapio) => {
      this.query = query;
      if (query["page"]) {
        this.paginator.pageIndex = query["page"] - 1;
      }
      if (query["tipo"]) {
        this.tipo = query["tipo"];
      }
    });
    this.buscar();
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cardapioService.get(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction.toLocaleUpperCase(),
            this.query.idCliente,
            this.query.idRestaurante,
            this.tipo,
            this.search
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.cadastroCardapios;
        }),
        catchError((error) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.setQueryParams();
        this.data = data;
      });
  }

  setQueryParams() {
    if (typeof this.query === "undefined") {
      return;
    }

    const queryParams = {
      ...this.query,
      page: this.paginator.pageIndex + 1,
      tipo: this.tipo,
    };

    if (queryParams.page === 1) {
      delete queryParams.page;
    }

    const keys = Object.keys(queryParams);
    keys.forEach((key) => {
      if (!queryParams[key] || queryParams[key] === "0") {
        delete queryParams[key];
      }
    });

    const url = this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams,
      })
      .toString();
    if (url !== this.router.url.toString()) {
      this.location.go(url);
    }
  }

  deletarData(cliente: Cardapio) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.cardapioService.delete(cliente).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Item removido com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  deletarDatas() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir itens",
        description:
          "Você realmente quer excluir esses itens? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cardapioService.deleteSelected(this.selection.selected).subscribe(
          (res: any) => {
            this.loadingService.present("Excluindo itens");
            this.helper.openSnackBar("Itens removidos com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  onTipoChange(e) {
    this.paginator.pageIndex = 0;
    this.buscar();
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.cardapioService
        .get(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction.toLocaleUpperCase(),
          this.query.idCliente,
          this.query.idRestaurante,
          this.tipo,
          e.toLocaleLowerCase()
        )
        .subscribe((res: CardapiosApi) => {
          this.data =
            this.paginator.pageIndex == 0
              ? res.cadastroCardapios
              : this.data.concat(res.cadastroCardapios);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = "";
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: Cardapio): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  adicionarCardapio() {
    this.router.navigate(["/cardapios/adicionar"], {
      queryParams: {
        idCliente: this.query.idCliente,
        idRestaurante: this.query.idRestaurante,
      },
    });
  }

  gerarExcel(cardapio: Cardapio) {
    this.loadingService.present("Gerando...");
    this.cardapioService.excel(cardapio.id).subscribe(
      (res) => {
        window.open(res, "_blank");
        this.helper.openSnackBar("Excel gerado com sucesso.");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }
}
