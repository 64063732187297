import { AuthService } from "./../auth.service";
import { Cardapio } from "./../../models/cardapio.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CardapioService {
  constructor(public api: ApiService, public auth: AuthService) {}

  // Cardapio/Buscar?String filtro, int numeroPagina, int registroPorPagina, int ordenacao, String sentidoOrdenacao, int idFilial, int idCliente, int idRestaurantes
  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idCliente: any = "%",
    idRestaurante: any = "%",
    tipo: string = "P",
    filtro: string = "%"
  ): Observable<CardapiosApi> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;

    return this.api.get("/cadastroCardapio/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
      tipo: tipo,
    });
  }

  getById(id): Observable<any> {
    return this.api.get(`/cadastroCardapio/buscar`, { id: id });
  }

  getTipoEspecial(idRestaurante: number) {
    return this.api.get(`/cadastroCardapio/buscarTipoEspecial`, {
      idRestaurante: idRestaurante,
    });
  }

  getSelect(): Observable<any> {
    return this.api.get(`/select/cadastroCardapio/buscar`);
  }

  post(cardapio: Cardapio): Observable<any> {
    return this.api.post("/cadastroCardapio/inserir", cardapio);
  }

  patch(cardapio: Cardapio): Observable<any> {
    return this.api.post("/cadastroCardapio/alterar", cardapio);
  }

  delete(cardapio: Cardapio): Observable<any> {
    return this.api.post("/cadastroCardapio/deletar", cardapio);
  }

  deleteSelected(cardapios: Cardapio[]): Observable<any> {
    return this.api.post("/cadastroCardapio/deletarLista", cardapios);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Cardapio, v2: Cardapio) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
  copy(
    idCadastroCardapioOrigem: number,
    idClienteDestino: number,
    idRestauranteDestino: number,
    nomeDestino: string
  ): Observable<any> {
    return this.api.get(`/cadastroCardapio/copiar`, {
      idCadastroCardapioOrigem,
      idFilialDestino: -99,
      idClienteDestino,
      idRestauranteDestino,
      nomeDestino,
    });
  }
  excel(idCadastroCardapio: number): Observable<any> {
    return this.api.get(`/cadastroCardapio/excel`, {
      idCadastroCardapio: idCadastroCardapio,
    });
  }
}

export interface CardapiosApi {
  cadastroCardapios: Cardapio[];
  numeroPaginas: number;
}
