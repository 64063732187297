<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Adicionar restaurate</h1>
        <div class="page-header__buttons">
          <button mat-stroked-button color="primary" routerLink="/restaurantes">
            VOLTAR
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">
        <h3 class="page-form__title">Dados do restaurate</h3>

        <mat-form-field class="page-form-control">
          <input
            matInput
            type="text"
            placeholder="Nome"
            [(ngModel)]="data.nome"
            name="nome"
            required
          />
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <mat-label>Cliente</mat-label>
          <mat-select name="cliente" [(ngModel)]="data.cliente" required>
            <mat-option>
              <ngx-mat-select-search
                [(ngModel)]="searchClientes"
                name="searchClientes"
                placeholderLabel="Pesquise aqui"
                noEntriesFoundLabel="Nenhum registro encontrado"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="
                let c of clientes | filter : searchClientes : 'nome';
                let i = index
              "
              [value]="c"
            >
              {{ c.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="page-form-control quad">
          <input
            matInput
            type="tel"
            placeholder="Limite de confirmação (HH:mm)"
            [(ngModel)]="data._horarioLimiteConfirmacao"
            name="horarioLimiteConfirmacao"
            mask="00:00"
            required
          />
        </mat-form-field>

        <mat-form-field class="page-form-control quad">
          <mat-label>Tipo do horário limite</mat-label>
          <mat-select
            name="tipoHorarioLimite"
            [(ngModel)]="data.tipoHorarioLimite"
            required
          >
            <mat-option value="H"> Hoje </mat-option>
            <mat-option value="O"> Ontem </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <textarea
            matInput
            rows="6"
            placeholder="Observação"
            [(ngModel)]="data.observacao"
            name="observacao"
          ></textarea>
        </mat-form-field>

        <mat-slide-toggle
          (change)="onSituacaoChange($event)"
          [checked]="data.situacao == 'A' ? true : false"
          >Situação:
          {{ data.situacao == "A" ? "Ativo" : "Inativo" }}</mat-slide-toggle
        >

        <hr />

        <button mat-raised-button color="primary" type="submit">SALVAR</button>
      </form>
    </div>
  </div>
</section>
