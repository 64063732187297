import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { AuthService } from './../../services/auth.service';
import { HelperService } from './../../services/helper.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  user: any = {};

  constructor(
    public helper: HelperService,
    public auth: AuthService,
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
  }

  esqueceuSenha(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha o formulário corretamente');
      return;
    }

    this.loadingService.present('Recuperando senha...');

    this.auth.forgotPassword(this.user).then((res: any) => {
      this.helper.openSnackBar('Verifique sua caixa de entrada. E-mail enviado.');
      f.reset();
      this.loadingService.dismiss();
    }).catch((e) => {
      this.helper.openSnackBar(e.error);
      this.loadingService.dismiss();
    })
  }

}
