import { Usuario } from './../../models/usuario.model';
import { ModalResetPasswordComponent } from './../../components/modal-reset-password/modal-reset-password.component';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { AuthService } from './../../services/auth.service';
import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Restaurante } from 'src/app/models/restaurante.model';
import { Cliente } from 'src/app/models/cliente.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: any = {};
  isLogin: boolean = true;

  constructor(
    public auth: AuthService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  login(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loadingService.present('Fazendo o login, aguarde...');

    this.auth.login(this.user)
      .subscribe((res: any) => {
        if (res) {
          this.auth.getUser(this.user).then((r: Usuario) => {

            if (r.tipo === 'N') {
              this.helper.openSnackBar('Você não tem permissão para entrar.');
              this.loadingService.dismiss();
              return;
            }

            if (r.solicitaAlteracao == 'S' && (this.user.senha == r.codigoRecuperacao)) {
              this.auth.isRecuperarSenha = true;
              const dialogRef = this.dialog.open(ModalResetPasswordComponent, {
                width: '420px',
              });

              dialogRef.afterClosed().subscribe(result => {
              });
            } else {
              this.auth.setToken(btoa(this.user.email + ':' + this.user.senha));
              this.router.navigate(['/']);
            }
            this.loadingService.dismiss()
            this.auth.setUser(r);
            if (r.tipo === 'A') {
              this.auth.setFilialUsuario(r.filiais[0]);
            } else if (r.tipo === 'C' || r.tipo === 'NUT' || r.tipo === 'CL') {

              if (r.tipo === 'CL') {
                var restauranteNv2 = new Restaurante();
                restauranteNv2.id = r.idRestaurante;
                
                var restaurante = new Restaurante();
                restaurante.restaurante = restauranteNv2;
                restaurante.idCliente = r.idCliente;

                this.auth.setRestaurante(restaurante);
              } else {
                this.auth.setRestaurante(r.restaurantes[0]);
              }
            }
          }, e => {
            this.helper.openSnackBar(e.error);
            this.loadingService.dismiss()
          });
        } else {
          this.loadingService.dismiss();
        }
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }

}
