import { Categoria } from './categoria.model';
import { Cliente } from './cliente.model';
import { Filial } from './filial.model';
import { Restaurante } from './restaurante.model';
export class Avaliacao {
    id: number;
    nome: string = '';
    dataInicial: Date;
    dataFinal: Date;
    filial: Filial = new Filial();
    restaurante: Restaurante = new Restaurante();
    cliente: Cliente = new Cliente();
    categorias: Categoria[] = [];
    [x: string]: any;
    constructor(obj?) {

    }
}