import { AuthService } from "./../../services/auth.service";
import { Filial } from "./../../models/filial.model";
import { FilialService } from "./../../services/class/filial.service";
import { RestauranteService } from "./../../services/class/restaurante.service";
import { Restaurante } from "./../../models/restaurante.model";
import { ClienteService } from "./../../services/class/cliente.service";
import { Cliente } from "./../../models/cliente.model";
// Default
import { Component, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-restaurantes-editar",
  templateUrl: "./restaurantes-editar.component.html",
  styleUrls: ["./restaurantes-editar.component.scss"],
})
export class RestaurantesEditarComponent implements OnInit {
  data: Restaurante = new Restaurante();
  filiais: Filial[] = [];
  clientes: Cliente[] = [];

  buscarSubscription: Subscription;
  buscarClientesSubscription: Subscription;
  userSubjectSubscription: Subscription;

  searchClientes: string = "";

  link: string;

  constructor(
    public restauranteService: RestauranteService,
    public filialService: FilialService,
    public clienteService: ClienteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((param) => this.buscar(param.id));
    this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
      this.router.navigate(["/restaurantes"])
    );
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
    this.buscarClientesSubscription.unsubscribe();
    this.userSubjectSubscription.unsubscribe();
  }

  buscar(id) {
    this.buscarSubscription = this.restauranteService
      .getById(id)
      .subscribe((res: Restaurante) => {
        this.data = res;
        const idFilial =
          this.auth.user.tipo === "A"
            ? this.auth.filialUsuario.filial.id
            : this.auth.user.idFilial;
        this.link = `http://admin.detella.com.br/feedback/contato?idFilial=${idFilial}&idCliente=${res.cliente.id}&idRestaurante=${res.id}`;

        if (res.horarioLimiteConfirmacao) {
          this.data._horarioLimiteConfirmacao = this.helper
            .moment(res.horarioLimiteConfirmacao)
            .format("HH:mm");
        }
        this.buscarClientes(idFilial);
      });
  }

  buscarClientes(id: number) {
    this.buscarClientesSubscription = this.clienteService
      .getSelect(id)
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Alterando item...");

    let horarioLimiteConfirmacao;

    if (this.data._horarioLimiteConfirmacao) {
      const formatted = this.helper
        .moment(this.data._horarioLimiteConfirmacao, "HH:mm")
        .format();
      console.log(formatted);

      horarioLimiteConfirmacao = formatted;
    }

    const data = {
      ...new Restaurante(),
      ...this.data,
      horarioLimiteConfirmacao,
    } as Restaurante;

    this.restauranteService.patch(data).subscribe(
      (res: any) => {
        this.router.navigate(["/restaurantes"]).then(() => {
          this.helper.openSnackBar("Item alterado com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  copy(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.helper.openSnackBar("Link copiado para sua área de transferência");
  }

  open(link: string) {
    window.open(link, "_blank");
  }

  isCustomer() {
    return this.auth.user.tipo === "CL";
  }
}
