<h1 mat-dialog-title>Editar prato padrão.</h1>
<div mat-dialog-content>
    <div class="page-form">
        <mat-form-field class="page-form-control">
            <input matInput name="limitePorcao" type="tel" placeholder="Limite porção" [(ngModel)]="data.limitePorcao"
                required>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="onYesClick()">Confirmar</button>
</div>