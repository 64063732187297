import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { DiaCadastroCardapio } from 'src/app/models/dia-cadastro-cardapio.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiaCadastroCardapioService {

  constructor(
    public api: ApiService
  ) { }

  patch(dia: DiaCadastroCardapio): Observable<any> {
    return this.api.post(`/diaCadastroCardapio/alterar`, dia);
  }
}
