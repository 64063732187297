import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { CardapioEspecial } from "src/app/models/cardapio-especial";
import { Observable, Subject } from "rxjs";
import { AuthService } from "../auth.service";
import { Cardapio } from "src/app/models/cardapio.model";

@Injectable({
  providedIn: "root",
})
export class CardapioEspecialService {
  active = new Subject<boolean>();

  constructor(public api: ApiService, public auth: AuthService) {}

  get(diaSemana: number, cardapio: Cardapio) {
    return this.api.get(`/cardapioEspecial/buscar`, {
      idCadCardapio: cardapio.id,
      diaSemana: diaSemana,
    });
  }

  post(cardapio: CardapioEspecial) {
    return this.api.post(`/cardapioEspecial/inserir`, cardapio);
  }

  patch(cardapio: CardapioEspecial) {
    return this.api.post(`/cardapioEspecial/alterar`, cardapio);
  }

  delete(cardapio: CardapioEspecial): Observable<any> {
    return this.api.post("/cardapioEspecial/deletar", cardapio);
  }

  deleteSelected(cardapios: CardapioEspecial[]): Observable<any> {
    return this.api.post("/cardapioEspecial/deletarLista", cardapios);
  }

  order(cardapios: CardapioEspecial[]): Observable<any> {
    return this.api.post("/cardapioEspecial/ordenar", cardapios);
  }
}
