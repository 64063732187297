<h1 mat-dialog-title>Olá, digite sua nova senha para recuperar a conta.</h1>
<div mat-dialog-content>
    <mat-form-field>
        <input matInput name="senha" type="password" placeholder="Sua nova senha" [(ngModel)]="user.senha" required>
    </mat-form-field>

    <mat-form-field>
        <input matInput name="re_senha" type="password" placeholder="Confirme sua nova senha" [(ngModel)]="user.re_senha" required>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="recuperarSenha()">Confirmar</button>
</div>