import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Feedback } from "src/app/models/feedback.model";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-modal-feedback",
  templateUrl: "./modal-feedback.component.html",
  styleUrls: ["./modal-feedback.component.scss"],
})
export class ModalFeedbackComponent implements OnInit {
  resposta: string = "";

  constructor(
    public dialogRef: MatDialogRef<ModalFeedbackComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: Feedback
  ) {
    if (this.data.resposta) {
      this.resposta = this.data.resposta;
    }
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close({
      resposta: this.resposta,
    });
  }
}
