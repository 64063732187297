import { Empresa } from "./../../models/empresa.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EmpresaService {
  constructor(public api: ApiService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = '%'
  ): Observable<EmpresasApi> {
    return this.api.get("/empresa/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
    });
  }

  getById(id): Observable<any> {
    return this.api.get(`/empresa/buscar`, { id: id });
  }

  getSelect(): Observable<any> {
    return this.api.get(`/select/empresa/buscar`);
  }

  post(empresa: Empresa): Observable<any> {
    return this.api.post("/empresa/inserir", empresa);
  }

  patch(empresa: Empresa): Observable<any> {
    return this.api.post("/empresa/alterar", empresa);
  }

  delete(empresa: Empresa): Observable<any> {
    return this.api.post("/empresa/deletar", empresa);
  }

  deleteSelected(empresas: Empresa[]): Observable<any> {
    return this.api.post("/empresa/deletarLista", empresas);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Empresa, v2: Empresa) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface EmpresasApi {
  empresas: Empresa[];
  numeroPaginas: number;
}
