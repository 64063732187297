import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Avaliacao } from "src/app/models/avaliacao.model";
import { AvaliacaoService } from "src/app/services/class/avaliacao.service";
import { HelperService } from "src/app/services/helper.service";

interface Props {
  idRestaurante: number;
}

@Component({
  selector: "app-dialog-avalicao-excel",
  templateUrl: "./dialog-avalicao-excel.component.html",
  styleUrls: ["./dialog-avalicao-excel.component.scss"],
})
export class DialogAvalicaoExcelComponent implements OnInit {
  idAvalicao: number = 0;
  avaliacoes: Avaliacao[] = [];

  constructor(
    public avaliacaoService: AvaliacaoService,
    public dialogRef: MatDialogRef<DialogAvalicaoExcelComponent>,
    public helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: Props
  ) {}

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this.avaliacaoService
      .get(-99, -99, "0", "ASC", this.data.idRestaurante)
      .subscribe((res) => (this.avaliacoes = res.avaliacoes));
  }

  dismiss(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (!this.idAvalicao) {
      this.helper.openSnackBar(
        "Selecione uma avalição para completar essa ação."
      );
      return;
    }

    this.dialogRef.close(this.idAvalicao);
  }
}
