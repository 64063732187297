<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Editar cardápio</h1>
        <div class="page-header__buttons">
          <button mat-stroked-button color="primary" (click)="goBack()">
            VOLTAR
          </button>
          <button mat-raised-button color="primary" (click)="copy()">
            COPIAR
          </button>
          <button
            mat-raised-button
            class="btn-excel"
            (click)="gerarExcel()"
            *ngIf="data.tipo === 'P'"
          >
            GERAR EXCEL
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">
        <h3 class="page-form__title">Dados do cardápio</h3>

        <mat-form-field class="page-form-control">
          <input
            matInput
            type="text"
            placeholder="Nome"
            [(ngModel)]="data.nome"
            name="nome"
            required
          />
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <textarea
            matInput
            rows="6"
            placeholder="Observação"
            [(ngModel)]="data.observacao"
            name="observacao"
          ></textarea>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <mat-label>Escolha uma data inicial</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDataInicial"
            name="pickerDataInicial"
            [(ngModel)]="data.dataInicial"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDataInicial"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerDataInicial></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <mat-label>Escolha uma data final</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDataFinal"
            name="pickerDataFinal"
            [(ngModel)]="data.dataFinal"
            readonly
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDataFinal"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerDataFinal></mat-datepicker>
        </mat-form-field>

        <div class="page-form-control" *ngIf="data.tipo === 'P'">
          <label for="">Selecione os dias</label>
          <div class="checkbox-list">
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onSegundaChange($event)"
                [checked]="data.segunda === 'S' ? true : false"
              >
                Segunda</mat-checkbox
              >
            </div>
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onTercaChange($event)"
                [checked]="data.terca === 'S' ? true : false"
              >
                Terça</mat-checkbox
              >
            </div>
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onQuartaChange($event)"
                [checked]="data.quarta === 'S' ? true : false"
              >
                Quarta</mat-checkbox
              >
            </div>
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onQuintaChange($event)"
                [checked]="data.quinta === 'S' ? true : false"
              >
                Quinta</mat-checkbox
              >
            </div>
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onSextaChange($event)"
                [checked]="data.sexta === 'S' ? true : false"
              >
                Sexta</mat-checkbox
              >
            </div>
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onSabadoChange($event)"
                [checked]="data.sabado === 'S' ? true : false"
              >
                Sábado</mat-checkbox
              >
            </div>
            <div class="checkbox-item">
              <mat-checkbox
                (change)="onDomingoChange($event)"
                [checked]="data.domingo === 'S' ? true : false"
              >
                Domingo</mat-checkbox
              >
            </div>
          </div>
        </div>

        <div class="page-form-control">
          <label id="page-radio-group-label">Selecione o tipo:</label>
          <mat-radio-group
            aria-labelledby="page-radio-group-label"
            class="page-radio-group"
            [(ngModel)]="data.tipo"
            name="tipo"
          >
            <mat-radio-button
              class="page-radio-button"
              name="tipo"
              value="P"
              required
              disabled
            >
              Padrão
            </mat-radio-button>
            <mat-radio-button
              class="page-radio-button"
              name="tipo"
              value="E"
              required
              disabled
            >
              Especial
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <ng-container *ngIf="data.tipo === 'E'">
          <hr />

          <h3 class="page-form__title">Imagem (400x400)</h3>

          <div class="page-form-control page-form-image">
            <img
              [src]="_global.imageUrl + data.imagem"
              alt="imagem"
              *ngIf="data.imagem"
            />
            <img
              src="assets/image-placeholder.png"
              alt="imagem"
              *ngIf="!data.imagem"
            />
            <button type="button" mat-button (click)="fileInput.click()">
              Escolher arquivo
            </button>
            <input
              hidden
              (change)="onFileSelected($event.target.files)"
              #fileInput
              type="file"
              id="imagem"
              accept="image/*"
            />
            <button
              class="page-form-image__button"
              (click)="removeImage()"
              type="button"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </ng-container>

        <div class="page-form-control">
          <mat-form-field>
            <mat-label>Pratos</mat-label>
            <mat-select name="prato" [(ngModel)]="prato" required>
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="searchPratos"
                  name="searchPratos"
                  placeholderLabel="Pesquise aqui"
                  noEntriesFoundLabel="Nenhum registro encontrado"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let p of pratos | filter: searchPratos:'nome'"
                [value]="p"
              >
                {{ p.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            mat-icon-button
            [attr.aria-label]="'Adicionar'"
            type="button"
            [disabled]="!prato.id"
            (click)="adicionarPratoPadrao()"
          >
            <mat-icon>add</mat-icon>
          </button>
          <ng-container *ngIf="data.tipo === 'P'">
            <mat-tab-group
              animationDuration="0ms"
              [selectedIndex]="selected"
              (selectedIndexChange)="selectedIndexChange($event)"
            >
              <mat-tab label="Segunda" [disabled]="data.segunda === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="1"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 0 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
              <mat-tab label="Terça" [disabled]="data.terca === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="2"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 1 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
              <mat-tab label="Quarta" [disabled]="data.quarta === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="3"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 2 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
              <mat-tab label="Quinta" [disabled]="data.quinta === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="4"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 3 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
              <mat-tab label="Sexta" [disabled]="data.sexta === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="5"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 4 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
              <mat-tab label="Sábado" [disabled]="data.sabado === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="6"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 5 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
              <mat-tab label="Domingo" [disabled]="data.domingo === 'N'">
                <app-pratos-padrao-dia
                  [diaSemana]="7"
                  [idCadCardapio]="data.id"
                  [cardapio]="data"
                  [idCliente]="data.cliente.id"
                  [idRestaurante]="data.restaurante.id"
                  *ngIf="selected == 6 && data.id"
                >
                </app-pratos-padrao-dia>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
          <ng-container *ngIf="data.tipo === 'E'">
            <app-pratos-padrao-dia
              [diaSemana]="99"
              [idCadCardapio]="data.id"
              [cardapio]="data"
              [idCliente]="data.cliente.id"
              [idRestaurante]="data.restaurante.id"
            >
            </app-pratos-padrao-dia>
          </ng-container>
        </div>

        <hr />

        <button mat-raised-button color="primary" type="submit">SALVAR</button>
      </form>
    </div>
  </div>
</section>
