import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { PratoPadrao } from "src/app/models/prato-padrao.model";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class PratoPadraoService {
  active = new Subject<boolean>();

  constructor(public api: ApiService) {}

  get(idCadCardapio: number, diaSemana: number) {
    return this.api.get(`/pratoPadrao/buscar`, {
      idCadCardapio: idCadCardapio,
      diaSemana: diaSemana,
    });
  }

  post(prato: PratoPadrao) {
    return this.api.post(`/pratoPadrao/inserir`, prato);
  }

  patch(prato: PratoPadrao) {
    return this.api.post(`/pratoPadrao/alterar`, prato);
  }

  delete(prato: PratoPadrao): Observable<any> {
    return this.api.post("/pratoPadrao/deletar", prato);
  }

  deleteSelected(pratos: PratoPadrao[]): Observable<any> {
    return this.api.post("/pratoPadrao/deletarLista", pratos);
  }

  order(pratos: PratoPadrao[]): Observable<any> {
    return this.api.post("/pratoPadrao/ordenar", pratos);
  }
}
