import { Subscription } from 'rxjs';
import { AuthService } from './../../services/auth.service';
import { EmpresaService } from './../../services/class/empresa.service';
import { Empresa } from './../../models/empresa.model';
// Default
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-empresas-criar',
  templateUrl: './empresas-criar.component.html',
  styleUrls: ['./empresas-criar.component.scss']
})
export class EmpresasCriarComponent implements OnInit {

  data: Empresa = new Empresa();

  userSubjectSubscription: Subscription;

  constructor(
    public empresaService: EmpresaService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.userSubjectSubscription = this.auth.userSubject.subscribe(o => this.router.navigate(['/empresas']));
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.empresaService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/empresas']).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
