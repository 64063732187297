export class Notificacao {

  id: any = null;
  titulo: string = '';
  descricao: string = '';
  dataInicial: Date | null = null;
  dataFinal: Date | null = null;
  horario: Date | null = null;
  situacao: string = 'A';
}
