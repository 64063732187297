<h2 mat-dialog-title>Gerar Excel</h2>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Avaliação</mat-label>
        <mat-select name="idAvalicao" [(ngModel)]="idAvalicao">
            <mat-option>Selecionar</mat-option>
            <mat-option *ngFor="let a of avaliacoes" [value]="a.id">{{a.nome}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="dismiss()">Fechar</button>
    <button mat-button (click)="submit()">Gerar</button>
</div>