import { AuthService } from "./../../services/auth.service";
import { Filial } from "./../../models/filial.model";
import { FilialService } from "./../../services/class/filial.service";
import { RestauranteService } from "./../../services/class/restaurante.service";
import { Restaurante } from "./../../models/restaurante.model";
import { ClienteService } from "./../../services/class/cliente.service";
import { Cliente } from "./../../models/cliente.model";
// Default
import { Component, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Router } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-restaurantes-criar",
  templateUrl: "./restaurantes-criar.component.html",
  styleUrls: ["./restaurantes-criar.component.scss"],
})
export class RestaurantesCriarComponent implements OnInit {
  data: Restaurante = new Restaurante();
  filiais: Filial[] = [];
  clientes: Cliente[] = [];

  buscarFiliaisSubscription: Subscription;
  buscarClientesSubscription: Subscription;
  userSubjectSubscription: Subscription;

  searchFiliais: string = "";
  searchClientes: string = "";

  constructor(
    public restauranteService: RestauranteService,
    public filialService: FilialService,
    public clienteService: ClienteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService
  ) {
    this.data._horarioLimiteConfirmacao = this.helper
      .moment()
      .set({ hours: 9, minutes: 0, seconds: 0 })
      .format("HH:mm");
  }

  ngOnInit() {
    this.buscarFilial();
    this.buscarClientes(this.data.filial.id);
    this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
      this.router.navigate(["/restaurantes"])
    );
  }
  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
    if (
      this.buscarClientesSubscription &&
      !this.buscarClientesSubscription.closed
    ) {
      this.buscarClientesSubscription.unsubscribe();
    }
  }

  buscarFilial() {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    this.data.filial.id = idFilial;
  }

  buscarClientes(id: number) {
    this.buscarClientesSubscription = this.clienteService
      .getSelect(id)
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Inserindo item...");

    let horarioLimiteConfirmacao;

    if (this.data._horarioLimiteConfirmacao) {
      const formatted = this.helper
        .moment(this.data._horarioLimiteConfirmacao, "HH:mm")
        .format();
      console.log(formatted);

      horarioLimiteConfirmacao = formatted;
    }

    const data = {
      ...new Restaurante(),
      ...this.data,
      horarioLimiteConfirmacao,
    } as Restaurante;

    this.restauranteService.post(data).subscribe(
      (res: any) => {
        this.router.navigate(["/restaurantes"]).then(() => {
          this.helper.openSnackBar("Item inserido com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }
}
