import { PratosEditarComponent } from "./pages/pratos-editar/pratos-editar.component";
import { PratosCriarComponent } from "./pages/pratos-criar/pratos-criar.component";
import { PratosComponent } from "./pages/pratos/pratos.component";
import { PratosClienteComponent } from "./pages/pratos-cliente/pratos-cliente.component";
import { CardapiosEditarComponent } from "./pages/cardapios-editar/cardapios-editar.component";
import { CardapiosCriarComponent } from "./pages/cardapios-criar/cardapios-criar.component";
import { CardapiosComponent } from "./pages/cardapios/cardapios.component";
import { CardapiosClienteComponent } from "./pages/cardapios-cliente/cardapios-cliente.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { AvaliacoesConfigComponent } from "./pages/avaliacoes-config/avaliacoes-config.component";
import { AvaliacoesComponent } from "./pages/avaliacoes/avaliacoes.component";
import { RestaurantesCriarComponent } from "./pages/restaurantes-criar/restaurantes-criar.component";
import { RestaurantesEditarComponent } from "./pages/restaurantes-editar/restaurantes-editar.component";
import { RestaurantesComponent } from "./pages/restaurantes/restaurantes.component";
import { ClientesCriarComponent } from "./pages/clientes-criar/clientes-criar.component";
import { ClientesEditarComponent } from "./pages/clientes-editar/clientes-editar.component";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { FiliaisCriarComponent } from "./pages/filiais-criar/filiais-criar.component";
import { FiliaisEditarComponent } from "./pages/filiais-editar/filiais-editar.component";
import { FiliaisComponent } from "./pages/filiais/filiais.component";
import { EmpresasEditarComponent } from "./pages/empresas-editar/empresas-editar.component";
import { EmpresasCriarComponent } from "./pages/empresas-criar/empresas-criar.component";
import { EmpresasComponent } from "./pages/empresas/empresas.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { UsuariosEditarComponent } from "./pages/usuarios-editar/usuarios-editar.component";
import { UsuariosCriarComponent } from "./pages/usuarios-criar/usuarios-criar.component";
import { UsuariosComponent } from "./pages/usuarios/usuarios.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { IsLoggedInGuard } from "./guards/is-logged-in.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FeedbacksComponent } from "./pages/feedbacks/feedbacks.component";
import { IsAdminLoggedInGuard } from "./guards/is-admin-logged-in.guard";
import { AvaliacoesListagemComponent } from "./pages/avaliacoes-listagem/avaliacoes-listagem.component";
import { AvaliacoesCriarComponent } from "./pages/avaliacoes-criar/avaliacoes-criar.component";
import { NotificacoesComponent } from "./pages/notificacoes/notificacoes.component";
import { NotificacoesCriarComponent } from "./pages/notificacoes-criar/notificacoes-criar.component";
import { NotificacoesEditarComponent } from "./pages/notificacoes-editar/notificacoes-editar.component";
import { PrivacyComponent } from "./admin/pages/privacy/privacy.component";
import { TermsComponent } from "./admin/pages/terms/terms.component";
import { FeedbackRootComponent } from "./feedback/feedback-root/feedback-root.component";

const routes: Routes = [
  {
    path: "feedback",
    component: FeedbackRootComponent,
    loadChildren: () =>
      import("./feedback/feedback.module").then((m) => m.FeedbackModule),
  },
  { path: "", component: HomeComponent, canActivate: [IsLoggedInGuard] },
  { path: "login", component: LoginComponent },
  { path: "esqueceu-senha", component: ResetPasswordComponent },
  {
    path: "feedbacks",
    component: FeedbacksComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "cardapios",
    component: CardapiosClienteComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "cardapios/listagem",
    component: CardapiosComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "cardapios/adicionar",
    component: CardapiosCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "cardapios/editar/:id",
    component: CardapiosEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  // { path: 'pratos', component: PratosClienteComponent, canActivate: [IsLoggedInGuard] },
  {
    path: "pratos",
    component: PratosComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "pratos/adicionar",
    component: PratosCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "pratos/editar/:id",
    component: PratosEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "avaliacoes",
    component: AvaliacoesComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "avaliacoes/listagem/:id",
    component: AvaliacoesListagemComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "avaliacoes/adicionar",
    component: AvaliacoesCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "avaliacoes/configuracoes/:id",
    component: AvaliacoesConfigComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "empresas",
    component: EmpresasComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "empresas/editar/:id",
    component: EmpresasEditarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "empresas/adicionar",
    component: EmpresasCriarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "filiais",
    component: FiliaisComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "filiais/editar/:id",
    component: FiliaisEditarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "filiais/adicionar",
    component: FiliaisCriarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "clientes",
    component: ClientesComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "clientes/editar/:id",
    component: ClientesEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "clientes/adicionar",
    component: ClientesCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "restaurantes",
    component: RestaurantesComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "restaurantes/editar/:id",
    component: RestaurantesEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "restaurantes/adicionar",
    component: RestaurantesCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "notificacoes",
    component: NotificacoesComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "notificacoes/adicionar",
    component: NotificacoesCriarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "notificacoes/editar/:id",
    component: NotificacoesEditarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "usuarios",
    component: UsuariosComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "usuarios/adicionar",
    component: UsuariosCriarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  {
    path: "usuarios/editar/:id",
    component: UsuariosEditarComponent,
    canActivate: [IsAdminLoggedInGuard],
  },
  { path: "not-found", component: NotFoundComponent },
  { path: "politica-de-privacidade", component: PrivacyComponent },
  { path: "termos-de-uso", component: TermsComponent },
  { path: "", redirectTo: "/", pathMatch: "full" },
  { path: "**", redirectTo: "/not-found" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
