import { Cliente } from "./cliente.model";
import { Filial } from "./filial.model";
import { Restaurante } from "./restaurante.model";

export class Feedback {
  id: number;
  nome: string = "";
  email: string = "";
  feedback: string = "";
  dataHora: string = "";
  resposta: string = "";
  filial: Filial = new Filial();
  cliente: Cliente = new Cliente();
  restaurante: Restaurante = new Restaurante();
  tipoPessoa: string = "";

  [x: string]: any;
}
