import { Usuario } from "./../../models/usuario.model";
import { UsuarioService } from "./../../services/class/usuario.service";
// default
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { GlobalService } from "./../../services/global.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { Cliente } from "src/app/models/cliente.model";
import { Restaurante } from "src/app/models/restaurante.model";
import { ClienteService } from "src/app/services/class/cliente.service";
import { RestauranteService } from "src/app/services/class/restaurante.service";
import { AuthService } from "src/app/services/auth.service";
import { ModalUserStatusComponent } from "src/app/components/modal-user-status/modal-user-status.component";
import { FilialService } from "src/app/services/class/filial.service";
import { Filial } from "src/app/models/filial.model";

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.scss"],
})
export class UsuariosComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ["select", "1", "2", "3", "4", "actions"];
  data: Usuario[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string = "";
  filterSubscription: Subscription;

  filial: Filial = new Filial();
  filiais: Filial[] = [];

  cliente: Cliente = new Cliente();
  clientes: Cliente[] = [];
  searchClientes: string = "";

  restaurante: Restaurante = new Restaurante();
  restaurantes: Restaurante[] = [];
  searchRestaurantes: string = "";

  status: string = "%";
  tipo: string = "%";
  situacao: boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Usuario>(true, []);

  constructor(
    public global: GlobalService,
    public auth: AuthService,
    public usuarioService: UsuarioService,
    public filialService: FilialService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    this.buscarClientes(idFilial);
  }

  ngOnDestroy() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.buscar();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.usuarioService.get(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction.toLocaleUpperCase(),
            this.cliente.id ? this.cliente.id : "%",
            this.restaurante.id ? this.restaurante.id : "%",
            this.situacao ? "A" : "I",
            this.tipo,
            this.status,
            this.search
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.usuarios;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.data = data;
      });
  }

  changeUserStatusDialog(usuario: Usuario) {
    const dialogRef = this.dialog.open(ModalUserStatusComponent, {
      width: "540px",
      data: usuario,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeStatus(usuario.id, "A", result.idRestaurante);
      }
    });
  }

  changeStatus(
    idUsuario: number,
    status: string = "R",
    idRestaurante: any = ""
  ) {
    this.loadingService.present(
      `${status === "A" ? "Aprovando" : "Reprovando"}...`
    );
    this.usuarioService
      .changeStatus(idUsuario, status, idRestaurante)
      .subscribe(
        (res) => {
          this.helper.openSnackBar(
            `Usuário ${status === "A" ? "aprovado" : "reprovado"} com sucesso!`
          );
          this.buscar();
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
  }

  deletarData(usuario: Usuario) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.usuarioService.delete(usuario).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Item removido com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  deletarDatas() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir itens",
        description:
          "Você realmente quer excluir esses itens? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usuarioService.deleteSelected(this.selection.selected).subscribe(
          (res: any) => {
            this.loadingService.present("Excluindo itens");
            this.helper.openSnackBar("Itens removidos com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.usuarioService
        .get(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction.toLocaleUpperCase(),
          this.cliente.id,
          this.restaurante.id,
          this.situacao ? "A" : "I",
          this.tipo,
          this.status,
          e.toLocaleLowerCase()
        )
        .subscribe((res: any) => {
          this.data =
            this.paginator.pageIndex == 0
              ? res.usuarios
              : this.data.concat(res.usuarios);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = "";
    this.buscar();
  }

  buscarClientes(idFilial) {
    this.clienteService
      .getSelect(idFilial)
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  buscarRestaurantes() {
    this.restauranteService
      .getSelect(this.cliente.id)
      .subscribe((res: Restaurante[]) => (this.restaurantes = res));
  }

  onClienteChange(e) {
    if (!e) {
      this.cliente = new Cliente();
      this.restaurante = new Restaurante();
      return;
    }
    this.buscar();
    this.buscarRestaurantes();
  }

  onRestauranteChange(e) {
    if (!e) {
      this.restaurante = new Restaurante();
      return;
    }
    this.buscar();
  }

  onStatusChange(e) {
    this.buscar();
  }

  onTipoChange(e) {

    this.buscar();
  }

  onSlideChange(e) {
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: Usuario): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
      }`;
  }
}
