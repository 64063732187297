import { AuthService } from "../auth.service";
import { Notificacao } from "../../models/notificacao.model";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificacaoService {
  constructor(public api: ApiService, public auth: AuthService) { }

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = "%",
    situacao: string = "A"
  ): Observable<NotificacoesApi> {

    return this.api.get("/notificacao/buscar", {
      filtro,
      numeroPagina,
      registroPorPagina,
      ordenacao,
      sentidoOrdenacao,
      situacao
    });
  }

  enviar(idNotificacao): Observable<any> {

    return this.api.get("/notificacao/enviar", { idNotificacao });
  }

  getById(id): Observable<any> {
    return this.api.get("/notificacao/buscar", { id: id });
  }

  post(notificacao: Notificacao): Observable<any> {
    return this.api.post("/notificacao/inserir", notificacao);
  }

  patch(notificacao: Notificacao): Observable<any> {
    return this.api.post("/notificacao/alterar", notificacao);
  }

  delete(notificacao: Notificacao): Observable<any> {
    return this.api.post("/notificacao/deletar", notificacao);
  }

  deleteSelected(notificacoes: Notificacao[]): Observable<any> {
    return this.api.post("/notificacao/deletarLista", notificacoes);
  }

  send(
    idNotificacao: number
  ): Observable<NotificacoesApi> {

    return this.api.get("/notificacao/enviar", {
      idNotificacao
    });
  }

  compareFn(v1: Notificacao, v2: Notificacao) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface NotificacoesApi {
  notificacoes: Notificacao[];
  numeroPaginas: number;
}
