<section class="not-found">
    <div class="container">
        <div class="not-found-wrapper">
            <div class="not-found__image">
                <img src="assets/not-found.png" alt="Erro 404">
            </div>
            <div class="not-found__content">
                <h4>Parece que<br />você está perdido...</h4>
                <button mat-flat-button color="primary" routerLink="/">Voltar ao início</button>
            </div>
        </div>
    </div>
</section>