import { AuthService } from "./../auth.service";
import { Cliente } from "./../../models/cliente.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ClienteService {
  constructor(public api: ApiService, public auth: AuthService) { }

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idCliente: any = "%",
    idRestaurante: any = "%",
    situacao: string = "A",
    filtro: string = "%"
  ): Observable<ClientesApi> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/cliente/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
      situacao: situacao,
    });
  }

  getById(id): Observable<any> {
    return this.api.get("/cliente/buscar", { id: id });
  }

  getSelect(idFilial?: any): Observable<any> {
    if (!idFilial) {
      idFilial =
        this.auth.user.tipo === "A"
          ? this.auth.filialUsuario.filial.id
          : this.auth.user.idFilial;
    }
    return this.api.get(`/select/cliente/buscar`, {
      idFilial: idFilial,
    });
  }

  post(cliente: Cliente): Observable<any> {
    return this.api.post("/cliente/inserir", cliente);
  }

  patch(cliente: Cliente): Observable<any> {
    return this.api.post("/cliente/alterar", cliente);
  }

  delete(cliente: Cliente): Observable<any> {
    return this.api.post("/cliente/deletar", cliente);
  }

  deleteSelected(clientes: Cliente[]): Observable<any> {
    return this.api.post("/cliente/deletarLista", clientes);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Cliente, v2: Cliente) {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface ClientesApi {
  clientes: Cliente[];
  numeroPaginas: number;
}
