<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar avalicação
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" (click)="goBack()">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" #form="ngForm" (ngSubmit)="submit(form)">
                <h3 class="page-form__title">
                    Dados da avaliação
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput type="text" placeholder="Nome" [(ngModel)]="data.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <mat-label>Escolha uma data inicial</mat-label>
                    <input matInput [matDatepicker]="pickerDataInicial" name="pickerDataInicial"
                        [(ngModel)]="data.dataInicial" readonly required>
                    <mat-datepicker-toggle matSuffix [for]="pickerDataInicial"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDataInicial></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <mat-label>Escolha uma data final</mat-label>
                    <input matInput [matDatepicker]="pickerDataFinal" name="pickerDataFinal"
                        [(ngModel)]="data.dataFinal" readonly required>
                    <mat-datepicker-toggle matSuffix [for]="pickerDataFinal"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDataFinal></mat-datepicker>
                </mat-form-field>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    CONTINUAR
                </button>
            </form>
        </div>
    </div>
</section>