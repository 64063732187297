import { AuthService } from './../../services/auth.service';
import { EmpresaService } from './../../services/class/empresa.service';
import { Empresa } from './../../models/empresa.model';
import { FilialService } from './../../services/class/filial.service';
import { Filial } from './../../models/filial.model';
// Default
import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filiais-criar',
  templateUrl: './filiais-criar.component.html',
  styleUrls: ['./filiais-criar.component.scss']
})
export class FiliaisCriarComponent implements OnInit {

  data: Filial = new Filial();

  empresas: Empresa[] = [];

  buscarEmpresasSubscription: Subscription;
  userSubjectSubscription: Subscription;

  searchEmpresas: string = '';

  constructor(
    public filialService: FilialService,
    public empresaService: EmpresaService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.buscarEmpresas();
    this.userSubjectSubscription = this.auth.userSubject.subscribe(o => this.router.navigate(['/filiais']));
  }

  ngOnDestroy() {
    this.buscarEmpresasSubscription.unsubscribe();
    this.userSubjectSubscription.unsubscribe();
  }

  buscarEmpresas() {
    this.buscarEmpresasSubscription = this.empresaService.getSelect()
      .subscribe((res: Empresa[]) => this.empresas = res);
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo item...');

    this.filialService.post(this.data)
      .subscribe((res: any) => {
        this.router.navigate(['/filiais']).then(() => {
          this.helper.openSnackBar('Item inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

}
