import { Filial } from "./filial.model";
import { Cliente } from "./cliente.model";

export class Restaurante {
  id: any = null;
  nome: string = "";
  observacao: string = "";
  filial: Filial = new Filial();
  cliente: Cliente;
  situacao: string = "A";
  horarioLimiteConfirmacao = new Date();
  _horarioLimiteConfirmacao: string = "";
  tipoHorarioLimite: "O" | "H" | "Z" = "H"; // O-Ontem H-Hoje
  [x: string]: any;

  constructor(obj?) {
    Object.assign(this);
  }
}
