<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Feedbacks</h1>
        <div class="page-header__buttons">
          <!-- <button mat-stroked-button color="warn" [disabled]="!selection.selected.length"
                        (click)="deletarDatas()">
                        DELETAR ITENS
                    </button> -->
          <button mat-raised-button color="primary" (click)="downloadExcel()">
            EXPORTAR EXCEL
          </button>
        </div>
      </div>

      <div class="page-table">
        <div
          class="loading-shade"
          *ngIf="isLoadingResults || isRateLimitReached"
        >
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="rate-limit-reached" *ngIf="isRateLimitReached">
            Não foi possível acessar os dados dessa listagem, aguarde um pouco e
            tente novamente.
          </div>
        </div>

        <div class="page-table-filters">
          <mat-form-field class="page-table__search page-form-control quad">
            <input
              matInput
              [(ngModel)]="search"
              (ngModelChange)="filter($event)"
              placeholder="Pesquise aqui"
            />
            <button
              mat-button
              *ngIf="search"
              matSuffix
              mat-icon-button
              aria-label="Limpar"
              (click)="clearFilter()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="page-form-control quad">
            <mat-label>Cliente</mat-label>
            <mat-select
              name="idCliente"
              [(ngModel)]="idCliente"
              (ngModelChange)="onClienteChange($event)"
              [disabled]="
                auth.user.tipo === 'NUT' ||
                auth.user.tipo === 'C' ||
                auth.user.tipo === 'CL'
              "
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="searchClientes"
                  name="searchClientes"
                  placeholderLabel="Pesquise aqui"
                  noEntriesFoundLabel="Nenhum registro encontrado"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option>Selecionar</mat-option>
              <mat-option
                *ngFor="
                  let c of clientes | filter : searchClientes : 'nome';
                  let i = index
                "
                [value]="c.id"
              >
                {{ c.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="page-form-control quad">
            <mat-label>Restaurante</mat-label>
            <mat-select
              name="idRestaurante"
              [(ngModel)]="idRestaurante"
              (ngModelChange)="onRestauranteChange($event)"
              [disabled]="
                auth.user.tipo === 'NUT' ||
                auth.user.tipo === 'C' ||
                auth.user.tipo === 'CL' ||
                !idCliente
              "
            >
              <mat-option>
                <ngx-mat-select-search
                  [(ngModel)]="searchRestaurantes"
                  name="searchRestaurantes"
                  placeholderLabel="Pesquise aqui"
                  noEntriesFoundLabel="Nenhum registro encontrado"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option>Selecionar</mat-option>
              <mat-option
                *ngFor="
                  let r of restaurantes | filter : searchRestaurantes : 'nome';
                  let i = index
                "
                [value]="r.id"
              >
                <ng-container *ngIf="idCliente">{{ r.nome }}</ng-container>
                <ng-container *ngIf="!idCliente"
                  >{{ r.cliente.nome }} - {{ r.nome }}</ng-container
                >
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="page-form-control quad">
            <mat-label>Status</mat-label>
            <mat-select
              name="status"
              [(ngModel)]="status"
              (ngModelChange)="onStatusChange($event)"
            >
              <mat-option value="P">Pendente</mat-option>
              <mat-option value="R">Respondida</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="page-table-container">
          <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="rate-limit-reached">Nenhum registro encontrado</div>
          </div>

          <table
            mat-table
            [dataSource]="data"
            matSort
            matSortActive="0"
            matSortDirection="asc"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
              <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
            </ng-container>

            <ng-container matColumnDef="2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <ng-container matColumnDef="3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
              <td mat-cell *matCellDef="let row">{{ row.cliente.nome }}</td>
            </ng-container>

            <ng-container matColumnDef="4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Restaurante
              </th>
              <td mat-cell *matCellDef="let row">{{ row.restaurante.nome }}</td>
            </ng-container>

            <ng-container matColumnDef="5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Data e hora
              </th>
              <td mat-cell *matCellDef="let row">
                {{ helper.moment(row.dataHora).format("DD/MM/YYYY HH:mm") }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <button
                  mat-icon-button
                  mat-button
                  matTooltip="Ver feedback"
                  aria-label="Ver feedback"
                  color="primary"
                  (click)="openFeedback(row)"
                >
                  <mat-icon>chat</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            [length]="resultsLength"
            [pageSizeOptions]="[15, 25, 40, 50]"
            [pageSize]="15"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</section>
