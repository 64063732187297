<h2 mat-dialog-title>Confirmados</h2>
<div mat-dialog-content>
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let user of data.confirmados"
      >{{ user._nomeUsuario }}
      <ng-container *ngIf="user.turno">
        -
        {{
          user.turno === "A"
            ? "Almoço"
            : user.turno === "J2"
            ? "Jantar 2° Turno"
            : "Jantar 3° Turno/Ceia"
        }}
      </ng-container>
      <ng-container *ngIf="user?.horario"> - {{ user?.horario }} </ng-container>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button cdkFocusInitial (click)="dismiss()">Fechar</button>
</div>
