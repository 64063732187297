import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardapioEspecial } from 'src/app/models/cardapio-especial';
import { CardapioEspecialService } from 'src/app/services/class/cardapio-especial.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-pratos-padrao-dia-editar',
  templateUrl: './pratos-padrao-dia-editar.component.html',
  styleUrls: ['./pratos-padrao-dia-editar.component.scss']
})
export class PratosPadraoDiaEditarComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CardapioEspecial,
    public dialogRef: MatDialogRef<PratosPadraoDiaEditarComponent>,
    public cardapioEspecialService: CardapioEspecialService,
    public loadingService: LoadingService,
    public helper: HelperService
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick() {
    this.loadingService.present('Alterando...');
    this.cardapioEspecialService.patch(this.data).subscribe(res => {
      this.dialogRef.close(true);
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

}
