<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Configurações da avaliação
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" (click)="goBack()">
                        VOLTAR
                    </button>
                    <button mat-stroked-button color="primary" (click)="copiarAvaliacao()">
                        COPIAR
                    </button>
                    <button mat-raised-button color="primary" (click)="alterarConfiguracao()">
                        SALVAR
                    </button>
                </div>
            </div>
        </div>
        <!-- avaliação -->
        <div class="page-form">
            <h3 class="page-form__title">
                Dados da avaliação
            </h3>

            <mat-form-field class="page-form-control">
                <input matInput type="text" placeholder="Nome" [(ngModel)]="avaliacao.nome" name="nome" required>
            </mat-form-field>

            <mat-form-field class="page-form-control half">
                <mat-label>Escolha uma data inicial</mat-label>
                <input matInput [matDatepicker]="pickerDataInicial" name="pickerDataInicial"
                    [(ngModel)]="avaliacao.dataInicial" readonly required>
                <mat-datepicker-toggle matSuffix [for]="pickerDataInicial"></mat-datepicker-toggle>
                <mat-datepicker #pickerDataInicial></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="page-form-control half">
                <mat-label>Escolha uma data final</mat-label>
                <input matInput [matDatepicker]="pickerDataFinal" readonly name="pickerDataFinal"
                    [(ngModel)]="avaliacao.dataFinal" required>
                <mat-datepicker-toggle matSuffix [for]="pickerDataFinal"></mat-datepicker-toggle>
                <mat-datepicker #pickerDataFinal></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="page-form">

            <div class="row">

                <div class="col">
                    <h3 class="page-form__title">
                        Categorias
                        <button mat-raised-button color="primary" (click)="addItem()" class="list-add">
                            ADICIONAR
                        </button>
                    </h3>
                    <div cdkDropList class="list" (cdkDropListDropped)="drop($event)" *ngIf="categorias.length">
                        <div class="box" *ngFor="let c of categorias; let i = index;" cdkDrag>
                            <span>{{c.nome}}</span>
                            <div class="actions">
                                <button mat-icon-button mat-button cdkDragHandle matTooltip="Mover" aria-label="Mover"
                                    color="primary" style="color: #a7a7a7;cursor: move;">
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                        </path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </button>
                                <button mat-icon-button mat-button matTooltip="Editar" aria-label="Editar"
                                    color="primary" (click)="editItem(c)">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-icon-button mat-button matTooltip="Excluir" aria-label="Excluir"
                                    color="warn" (click)="deletarCategoria(c, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h3 class="page-form__title">
                        {{mutateData.id ? 'Editar' : 'Adicionar'}} categoria
                        <button mat-raised-button color="primary" type="submit" class="list-add" [disabled]="!editable"
                            (click)="submitCategoria(form)">
                            SALVAR
                        </button>
                    </h3>
                    <form (ngSubmit)="submitCategoria(form)" #form="ngForm">
                        <mat-form-field class="page-form-control">
                            <input matInput type="text" placeholder="Nome" [(ngModel)]="mutateData.nome" #nomeCategoria
                                name="nome" [disabled]="!editable" required>
                        </mat-form-field>

                        <h3 class="page-form__title">
                            Selecione uma imagem
                        </h3>

                        <div class="page-form-control page-form-image">
                            <img [src]="mutateData.imagem ? _global.imageUrl+mutateData.imagem : 'assets/image-placeholder.png'"
                                alt="Produto">
                            <button type="button" mat-stroked-button (click)="fileInput.click()"
                                [disabled]="!editable">Escolher
                                arquivo</button>
                            <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file"
                                id="imagem" accept="image/*">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>