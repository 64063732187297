import { Empresa } from './empresa.model';
export class Filial {

    id: number;
    nome: string = '';
    cnpj: string = '';
    uf: string = '';
    cidade: string = '';
    empresa: Empresa;
    situacao: string = 'A';
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}