import { Observable } from "rxjs";
import { Usuario } from "./../../models/usuario.model";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  constructor(public api: ApiService, public auth: AuthService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idCliente: any,
    idRestaurante: any,
    situacao: string,
    tipo: string,
    status: string,
    filtro: string = "%"
  ): Observable<UsuariosApi> {
    const idFilial =
      this.auth.user.tipo === "A"
        ? this.auth.filialUsuario.filial.id
        : this.auth.user.idFilial;
    return this.api.get("/usuario/buscar", {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idFilial: idFilial,
      idCliente: idCliente,
      idRestaurante: idRestaurante,
      situacao: situacao,
      status: status,
      tipo: tipo,
    });
  }

  getById(id): Observable<any> {
    return this.api.get(`/usuario/buscar?id=${id}`);
  }

  getByEmail(email): Observable<any> {
    return this.api.get(`/usuario/buscar?email=${email}`);
  }

  post(user: Usuario): Observable<any> {
    return this.api.post("/usuario/inserir", user);
  }

  patch(user: Usuario): Observable<any> {
    return this.api.post("/usuario/alterar", user);
  }

  changeStatus(id: number, status: string, idRestaurante: number) {
    return this.api.get("/usuario/alterarStatus", {
      id: id,
      status: status,
      idRestaurante: idRestaurante,
    });
  }

  delete(user: Usuario): Observable<any> {
    return this.api.post("/usuario/deletar", user);
  }

  deleteSelected(users: Usuario[]): Observable<any> {
    return this.api.post("/usuario/deletarLista", users);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface UsuariosApi {
  usuarios: Usuario[];
  numeroPaginas: number;
}
