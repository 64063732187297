import { PratoService, PratosApi } from "./../../services/class/prato.service";
import { Prato } from "./../../models/prato.model";
// default
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "./../../services/auth.service";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { GlobalService } from "./../../services/global.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components/dialog/dialog.component";

export interface Query {
  idCliente;
  idRestaurante;
}

@Component({
  selector: "app-pratos",
  templateUrl: "./pratos.component.html",
  styleUrls: ["./pratos.component.scss"],
})
export class PratosComponent implements OnInit {
  displayedColumns: string[] = ["select", "imagem", "1", "2", "3", "actions"];
  // imagem, nome, observacao, situacao
  data: Prato[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Prato>(true, []);

  userSubjectSubscription: Subscription;

  query: Query;

  constructor(
    public _global: GlobalService,
    public pratoService: PratoService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((query: Query) => (this.query = query));
    this.userSubjectSubscription = this.auth.userSubject.subscribe((stats) => {
      if (stats) {
        this.buscar();
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.buscar();
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.pratoService.get(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction.toLocaleUpperCase()
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.pratos;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.data = data;
      });
  }

  deletarData(prato: Prato) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.pratoService.delete(prato).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Item removido com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  deletarDatas() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir itens",
        description:
          "Você realmente quer excluir esses itens? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pratoService.deleteSelected(this.selection.selected).subscribe(
          (res: any) => {
            this.loadingService.present("Excluindo itens");
            this.helper.openSnackBar("Itens removidos com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.pratoService
        .get(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction.toLocaleUpperCase(),
          e.toLocaleLowerCase()
        )
        .subscribe((res: PratosApi) => {
          this.data =
            this.paginator.pageIndex == 0
              ? res.pratos
              : this.data.concat(res.pratos);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = "";
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: Prato): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  adicionarPrato() {
    this.router.navigate(["/pratos/adicionar"], {
      queryParams: {
        idCliente: this.query.idCliente,
        idRestaurante: this.query.idRestaurante,
      },
    });
  }
}
