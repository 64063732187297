import { AuthService } from "./../../services/auth.service";
import { FilialUsuario } from "./../../models/filial-usuario.model";
import { FilialService } from "./../../services/class/filial.service";
import { NgForm } from "@angular/forms";
import { HelperService } from "./../../services/helper.service";
import { Subscription } from "rxjs";
import { LoadingService } from "./../../services/loading.service";
import { UsuarioService } from "./../../services/class/usuario.service";
import { Usuario } from "./../../models/usuario.model";
import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Filial } from "src/app/models/filial.model";
import { FilialUsuarioService } from "src/app/services/class/filial-usuario.service";
import { Cliente } from "src/app/models/cliente.model";
import { Restaurante } from "src/app/models/restaurante.model";
import { ClienteService } from "src/app/services/class/cliente.service";
import { RestauranteService } from "src/app/services/class/restaurante.service";
import { UsuarioRestauranteService } from "src/app/services/class/usuario-restaurante.service";
import { MatSelectionListChange } from "@angular/material/list";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "app-usuarios-editar",
  templateUrl: "./usuarios-editar.component.html",
  styleUrls: ["./usuarios-editar.component.scss"],
})
export class UsuariosEditarComponent implements OnInit {
  data: Usuario = new Usuario();
  loading: boolean = false;
  hide: boolean = true;

  idUsuario: number;
  filiais: any[] = [];

  idCliente: number;
  searchClientes: string = "";
  clientes: Cliente[] = [];

  searchRestaurantes: string = "";
  _restaurantes: Restaurante[] = [];
  restaurantes: Restaurante[] = [];
  restaurantesSelected: Restaurante[] = [];

  filiaisSelected: any[] = [];

  buscarSubscription: Subscription;
  buscarFiliaisSubscription: Subscription;
  userSubjectSubscription: Subscription;
  ignoreRestauranteSelection = false;

  constructor(
    public usuarioService: UsuarioService,
    public filialService: FilialService,
    public filialUsuarioService: FilialUsuarioService,
    public clienteService: ClienteService,
    public restauranteService: RestauranteService,
    public route: ActivatedRoute,
    public router: Router,
    public loadingService: LoadingService,
    public helper: HelperService,
    public auth: AuthService,
    public usuarioRestauranteService: UsuarioRestauranteService
  ) {}

  ngOnInit() {
    this.buscarFiliais();
    this.route.params.subscribe((param) => {
      this.idUsuario = param.id;
      this.buscar(param.id);
      this.buscarFiliaisUsuario(param.id);
      this.buscarUsuarioRestaurante(param.id);
      this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
        this.router.navigate(["/usuarios"])
      );
    });
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
    this.buscarSubscription.unsubscribe();
    this.buscarFiliaisSubscription.unsubscribe();
  }

  buscarFiliais() {
    this.buscarFiliaisSubscription = this.filialService
      .getSelect()
      .subscribe((res: Filial[]) => (this.filiais = res));
  }

  buscarClientes(idFilial: number) {
    this.clienteService
      .getSelect(idFilial)
      .subscribe((res: Cliente[]) => (this.clientes = res));
  }

  buscarRestaurantes(idCliente: number) {
    console.log("buscarRestaurantes");
    this.ignoreRestauranteSelection = true;
    this.restauranteService.getSelect(idCliente).subscribe({
      next: (res: Restaurante[]) => {
        this._restaurantes = res;
        this.restaurantes = res.filter((restaurante) =>
          this.restaurantesSelected.some((r) => r.id === restaurante.id)
        );
      },
      complete: () => {
        console.log("buscarRestaurantes 2");
        this.ignoreRestauranteSelection = false;
      },
      error: () => {
        console.log("buscarRestaurantes 3");
        this.ignoreRestauranteSelection = false;
      },
    });
  }

  buscarUsuarioRestaurante(idUsuario) {
    this.usuarioRestauranteService.get(idUsuario).subscribe((res) => {
      this.restaurantesSelected = res;
    });
  }

  @ViewChild("restaurantesList") restaurantesListChecked;

  onRestaurantesSelectionChange(
    event: MatCheckboxChange,
    restauranteSelected: Restaurante
  ) {
    this.loadingService.present(
      `${event.checked ? "Inserindo" : "Removendo"} restaurante...`
    );

    if (event.checked) {
      this.usuarioRestauranteService
        .post(this.data.id, restauranteSelected.id)
        .subscribe((res) => {
          this.buscarUsuarioRestaurante(this.data.id);
          this.helper.openSnackBar("Restaurantes atualizados.");
          this.loadingService.dismiss();
        });
    } else {
      this.usuarioRestauranteService
        .delete(this.data.id, restauranteSelected.id)
        .subscribe((res) => {
          this.buscarUsuarioRestaurante(this.data.id);
          this.helper.openSnackBar("Restaurantes atualizados.");
          this.loadingService.dismiss();
        });
    }
  }

  isRestauranteSelected(idRestaurante): boolean {
    return this.restaurantesSelected.some(
      (r) => r.restaurante.id === idRestaurante
    );
  }

  isClienteSelected(idCliente): boolean {
    return this.restaurantesSelected.some((r) => r.idCliente === idCliente);
  }

  onFilialChange(ev) {
    if (this.data.idFilial == ev) return;
    this.data.idCliente = null;
    this.data.idRestaurante = null;
    this.buscarClientes(ev);
  }

  onClienteChange(ev) {
    if (!ev) return;
    this.data.idRestaurante = null;
    this.buscarRestaurantes(ev);
  }

  buscar(id: number) {
    this.loading = true;
    this.buscarSubscription = this.usuarioService.getById(id).subscribe(
      (res: Usuario) => {
        this.data = res;
        this.loading = false;
        if (res.idFilial) {
          this.buscarClientes(res.idFilial);
        }

        if (
          res.idCliente &&
          (res.tipo === "N" || res.tipo === "NUT" || res.tipo == "CL")
        ) {
          this.buscarRestaurantes(res.idCliente);
        }
      },
      (e) => (this.loading = false)
    );
  }

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Alterando item...");

    this.usuarioService.patch(this.data).subscribe(
      (res: any) => {
        this.router.navigate(["/usuarios"]).then(() => {
          if (this.auth.user.id === this.data.id) {
            this.auth.setUser(this.data);
          }
          this.helper.openSnackBar("item alterado com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => this.loadingService.dismiss()
    );
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  buscarFiliaisUsuario(id: number) {
    this.filialUsuarioService.get(id).subscribe((res: FilialUsuario[]) => {
      this.data.filiais = res;
    });
  }

  onFiliaisChange(event: any[]) {
    this.filiaisSelected = event;
  }

  onFiliaisOptionChange(ev, index) {
    const item = this.filiais[index];
    const temItem = this.filiaisSelected.some((f) => f.id === item.id);
    this.loadingService.present("Carregando...");
    if (temItem) {
      this.filialUsuarioService
        .post(this.idUsuario, item.id)
        .subscribe((res) => {
          this.buscarFiliaisUsuario(this.data.id);
          this.loadingService.dismiss();
        });
    } else {
      this.filialUsuarioService
        .delete(this.idUsuario, item.id)
        .subscribe((res) => {
          this.buscarFiliaisUsuario(this.data.id);
          this.loadingService.dismiss();
        });
    }
  }
}
