import { Filial } from './filial.model';

export class FilialUsuario {

    id: number;
    padrao: string = 'N';
    nomeFilial: string = '';
    filial: Filial = new Filial();
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}