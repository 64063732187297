import { Restaurante } from "./restaurante.model";
import { Cliente } from "./cliente.model";
import { Filial } from "./filial.model";

export class Prato {
  id: number;
  nome: string = "";
  observacao: string = "";
  imagem: string = "";
  filial: Filial = new Filial();
  cliente: Cliente = new Cliente();
  restaurante: Restaurante = new Restaurante();
  situacao: string = "A";
  principal: string = "N";
  [x: string]: any;

  constructor(obj?) {
    Object.assign(this);
  }
}
