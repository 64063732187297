import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-dialog-confirmed-list",
  templateUrl: "./dialog-confirmed-list.component.html",
  styleUrls: ["./dialog-confirmed-list.component.scss"],
})
export class DialogConfirmedListComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmedListComponent>,
    public helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
