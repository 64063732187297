import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  moment;
  emailRegex: RegExp;

  constructor(
    private _snackBar: MatSnackBar
  ) { 
    moment.locale('pt-BR');
    this.moment = moment;
    this.emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3200,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['white-snackbar']
    });
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object).values(control.controls).forEach(c => this.formMarkAllTouched(c));
      }
    });
  }
}
