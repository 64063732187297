import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { CardapioEspecial } from 'src/app/models/cardapio-especial';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { CardapioEspecialService } from 'src/app/services/class/cardapio-especial.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HelperService } from 'src/app/services/helper.service';
import { DialogComponent } from '../dialog/dialog.component';
import { Prato } from 'src/app/models/prato.model';
import { PratoService } from 'src/app/services/class/prato.service';
import { CardapioService } from 'src/app/services/class/cardapio.service';
import { GlobalService } from 'src/app/services/global.service';
import { Cardapio } from 'src/app/models/cardapio.model';

@Component({
  selector: 'app-pratos-padrao-dia-order',
  templateUrl: './pratos-padrao-dia-order.component.html',
  styleUrls: ['./pratos-padrao-dia-order.component.scss']
})
export class PratosPadraoDiaOrderComponent implements OnInit {

  displayedColumns: string[] = ['imagem', '1', '2', 'actions'];
  // nome, situacao
  data: CardapioEspecial[] = [];
  buscarSubscription: Subscription;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  prato: Prato = new Prato();
  cardapios: CardapioEspecial[] = [];
  searchPratos: string = '';
  buscarPratosSubscription: Subscription;

  selection = new SelectionModel<CardapioEspecial>(true, []);

  cardapio: Cardapio = new Cardapio();
  diaSemana: number;

  constructor(
    public dialogRef: MatDialogRef<PratosPadraoDiaOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public dataRef: any,
    public cardapioService: CardapioService,
    public cardapioEspecialService: CardapioEspecialService,
    public loadingService: LoadingService,
    public helper: HelperService,
    public dialog: MatDialog,
    public pratoService: PratoService,
    public global: GlobalService
  ) {
    this.cardapio = this.dataRef.cardapio;
    this.diaSemana = this.dataRef.diaSemana;
    this.buscarCardapiosEspeciais(this.dataRef.idRestaurante);
  }

  ngOnInit() {
    this.buscar();
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  buscar() {
    this.isLoadingResults = true;
    this.buscarSubscription = this.cardapioEspecialService.get(this.diaSemana, this.cardapio)
      .subscribe((res: CardapioEspecial[]) => {
        this.data = res;
        this.resultsLength = res.length;
        this.isLoadingResults = false;
      }, e => {
        this.isRateLimitReached = true;
        this.isLoadingResults = false;
      })
  }

  buscarCardapiosEspeciais(idRestaurante) {
    this.cardapioService.getTipoEspecial(idRestaurante)
      .subscribe((res: CardapioEspecial[]) => this.cardapios = res);
  }

  deletarData(prato: CardapioEspecial) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir item',
        description: 'Você realmente quer excluir esse item? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo item...');
        this.cardapioEspecialService.delete(prato).subscribe((res: any) => {
          this.helper.openSnackBar('Item removido com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  filter(e) {
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.cardapioEspecialService.get(this.diaSemana, this.cardapio)
        .subscribe((res: CardapioEspecial[]) => {
          this.data = res;
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: CardapioEspecial): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(this.cardapios);
  }

  adicionarPratosEspecialDia() {
    this.loadingService.present('Adicionando...');
    const cardapio: CardapioEspecial = new CardapioEspecial();
    cardapio.idCadCardapio = this.cardapio.id;
    cardapio.idCadCardapioEspecial = this.prato.id;
    cardapio.diaSemana = this.diaSemana;
    cardapio.idPrato = this.prato.id;
    this.cardapioEspecialService.post(cardapio).subscribe(res => {
      this.buscar();
      this.loadingService.dismiss();
    }, e => this.loadingService.dismiss());
  }

}
