import { Questao } from "./questao.model";

export class Categoria {
  id: number = 0;
  idAvaliacao: number = 0;
  nome: string = "";
  imagem: string = "";
  ordem: number = 99;
  [x: string]: any;
  constructor() {
    Object.assign(this);
  }
}
