import { FilialUsuario } from "./../../models/filial-usuario.model";
import { GlobalService } from "./../../services/global.service";
import { AuthService } from "./../../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FilialService } from "src/app/services/class/filial.service";
import { Filial } from "src/app/models/filial.model";
import { Restaurante } from "src/app/models/restaurante.model";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  restaurante: Restaurante = new Restaurante();
  filialUsuario: FilialUsuario = new FilialUsuario();
  filiais: Filial[] = [];
  search: string = "";

  pages = [
    { name: "Home", icon: "fas fa-home", route: "/" },
    { name: "Cardápios", icon: "fas fa-list", route: "/cardapios" },
    { name: "Pratos", icon: "fas fa-drumstick-bite", route: "/pratos" },
    // { name: "Empresas", icon: "fas fa-building", route: "/empresas" },
    //{ name: "Filiais", icon: "fas fa-code-branch", route: "/filiais" },
    { name: "Clientes", icon: "fas fa-user-tie", route: "/clientes" },
    { name: "Restaurantes", icon: "fas fa-utensils", route: "/restaurantes" },
    { name: "Notificações", icon: "fas fa-bell", route: "/notificacoes" },
    { name: "Avaliações", icon: "fas fa-star", route: "/avaliacoes" },
    { name: "Feedbacks", icon: "fas fa-comments", route: "/feedbacks" },
    { name: "Usuários", icon: "fas fa-users", route: "/usuarios" },
  ];

  pagesNut = [
    { name: "Home", icon: "fas fa-home", route: "/" },
    { name: "Feedbacks", icon: "fas fa-comments", route: "/feedbacks" },
    { name: "Cardápios", icon: "fas fa-list", route: "/cardapios" },
  ];

  pagesCli = [
    { name: "Home", icon: "fas fa-home", route: "/" },
    { name: "Feedbacks", icon: "fas fa-comments", route: "/feedbacks" },
    { name: "Restaurantes", icon: "fas fa-utensils", route: "/restaurantes" }
  ];


  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router,
    public filialService: FilialService
  ) {
    this.auth.userSubject.subscribe(
      (o) => (this.filialUsuario = this.auth.user.filiais[0])
    );
  }

  ngOnInit() {
    if (this.auth.user.tipo === "A") {
      const filialUsuario = this.auth.getFilialUsuarioStorage();
      if (filialUsuario) {
        this.filialUsuario = filialUsuario;
      } else {
        this.filialUsuario = this.auth.user.filiais[0];
        this.auth.setFilialUsuario(this.auth.user.filiais[0]);
      }
    } else {
      const restaurante = this.auth.getRestauranteStorage();
      if (restaurante) {
        this.restaurante = restaurante;
      } else {
        this.restaurante = this.auth.user.restaurantes[0];
        this.auth.setRestaurante(this.restaurante);
      }
    }
  }

  goTo(page: string) {
    this.router.navigate([page]).then(() => {
      this.global.menuOpen = false;
    });
  }

  logout() {
    this.router.navigate(["/login"]).then(() => {
      this.global.menuOpen = false;
      this.auth.logout();
    });
  }

  changeFilialUsuario(filialUsuario: FilialUsuario) {
    this.filialUsuario = filialUsuario;
    this.auth.setFilialUsuario(this.filialUsuario);
  }

  changeRestaurante(restaurante: Restaurante) {
    this.restaurante = restaurante;
    this.auth.setRestaurante(restaurante);
  }

  buscarFiliais() {
    this.filialService.getSelect().subscribe((res: Filial[]) => {
      this.filiais = res;
      if (this.auth.user.tipo !== "A") {
        const filial = res.find((f) => f.id === this.auth.user.idFilial);
        this.filialUsuario.filial = filial;
        this.filialUsuario.padrao = "S";
        this.auth.user.filiais.push(this.filialUsuario);
      }
    });
  }
}
