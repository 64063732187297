import { DialogCopyAvaliacaoComponent } from "./../../components/dialog-copy-avaliacao/dialog-copy-avaliacao.component";
import { AuthService } from "./../../services/auth.service";
import { Questao } from "./../../models/questao.model";
import { NgForm } from "@angular/forms";
import { HttpProgressEvent } from "@angular/common/http";
import { Categoria } from "./../../models/categoria.model";
import { AvaliacaoService } from "./../../services/class/avaliacao.service";
import { Avaliacao } from "./../../models/avaliacao.model";
// default
import { HelperService } from "./../../services/helper.service";
import { LoadingService } from "./../../services/loading.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CategoriaService } from "src/app/services/class/categoria.service";
import { GlobalService } from "src/app/services/global.service";
import { DialogComponent } from "src/app/components/dialog/dialog.component";

@Component({
  selector: "app-avaliacoes-config",
  templateUrl: "./avaliacoes-config.component.html",
  styleUrls: ["./avaliacoes-config.component.scss"],
})
export class AvaliacoesConfigComponent implements OnInit {
  @ViewChild("nomeCategoria") nomeCategoriaInput: ElementRef;
  avaliacao: Avaliacao = new Avaliacao();
  categorias: Categoria[] = [];
  questoes: Questao[] = [];
  selectedCategoriaIndex: number = 0;

  file: File;
  progress: number = 0;

  buscarSubscription: Subscription;
  userSubjectSubscription: Subscription;

  constructor(
    public _global: GlobalService,
    public avaliacaoService: AvaliacaoService,
    public categoriaService: CategoriaService,
    public route: ActivatedRoute,
    public loadingService: LoadingService,
    public helper: HelperService,
    public router: Router,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.buscar(param.id);
      this.buscarCategorias(param.id);
    });
    this.userSubjectSubscription = this.auth.userSubject.subscribe((o) =>
      this.router.navigate(["/avaliacoes"])
    );
  }

  ngOnDestroy() {
    this.userSubjectSubscription.unsubscribe();
  }

  buscar(id: number) {
    this.loadingService.present("Buscando avaliação...");
    this.buscarSubscription = this.avaliacaoService.getById(id).subscribe(
      (res: Avaliacao) => {
        this.avaliacao = res;
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  buscarCategorias(idAvaliacao: number) {
    this.categoriaService.get(idAvaliacao).subscribe((res: Categoria[]) => {
      this.categorias = res.map((c, i) => {
        i == 0 ? (c.disabled = true) : (c.disabled = false);
        c.ordem = i;
        return c;
      });
    });
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked
      ? (this.avaliacao.situacao = "A")
      : (this.avaliacao.situacao = "I");
  }

  copiarAvaliacao() {
    const dialogRef = this.dialog.open(DialogCopyAvaliacaoComponent, {
      width: "430px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.loadingService.present("Copiando avaliação...");
        this.avaliacaoService
          .copy(
            this.avaliacao.id,
            result.idCliente,
            result.idRestaurante,
            result.nomeDestino,
            this.avaliacao
          )
          .subscribe(
            (res) => {
              this.helper.openSnackBar("Avaliação copiada com sucesso.");
              window.open(`avaliacoes/configuracoes/${res}`);
              this.loadingService.dismiss();
            },
            (e) => this.loadingService.dismiss()
          );
      }
    });
  }

  // categorias
  drop(event: CdkDragDrop<Categoria[]>) {
    this.loadingService.present("Ordenando...");
    moveItemInArray(this.categorias, event.previousIndex, event.currentIndex);
    this.categorias = this.categorias.map((c, i) => {
      if (c.disabled) {
        this.selectedCategoriaIndex = i;
      }
      c.isEditing = false;
      c.ordem = i;
      return c;
    });
    this.categoriaService.order(this.categorias).subscribe(
      (res) => {
        this.helper.openSnackBar("Lista ordenada.");
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  alterarCategoria() {
    const categoria = this.categorias[this.selectedCategoriaIndex];

    if (categoria.isEditing) {
      this.loadingService.present("Alterando...");
      this.categoriaService.patch(categoria).subscribe(
        (res) => {
          this.helper.openSnackBar("Item alterado com sucesso!");
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
    } else {
      categoria.isEditing = !categoria.isEditing;
    }
  }

  deletarCategoria(categoria: Categoria, index: number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir item",
        description:
          "Você realmente quer excluir esse item? Esse processe não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo item...");
        this.categoriaService.delete(categoria).subscribe(
          (res) => {
            this.helper.openSnackBar("Item removido com sucesso.");
            if (this.selectedCategoriaIndex == index) {
              this.selectedCategoriaIndex = 0;
            }
            this.buscarCategorias(this.avaliacao.id);
            this.loadingService.dismiss();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present("0%");
    const id = this.categorias[this.selectedCategoriaIndex].id;
    this.submitImages(this.file, `/avaliacaoCategoria/imagem?id=${id}`)
      .then((res: any) => {
        this.categorias[this.selectedCategoriaIndex].imagem = res.body;
        this.loadingService.dismiss();
      })
      .catch(() => {
        this.progress = 0;
        this.fileInput.nativeElement.value = "";
        this.loadingService.dismiss();
      });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.avaliacaoService.postFile(file, url, "image").subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        },
        (err) => reject(err)
      );
    });
  }
  // end categorias

  mutateData: Categoria = new Categoria();
  editable: boolean = false;

  addItem() {
    this.mutateData = new Categoria();
    this.editable = true;
    this.focusInput();
  }

  editItem(item: Categoria) {
    this.mutateData = item;
    this.editable = true;
    this.focusInput();
  }

  cancelItem() {
    this.mutateData = new Categoria();
    this.editable = false;
  }

  focusInput() {
    setTimeout(() => {
      this.nomeCategoriaInput.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.nomeCategoriaInput.nativeElement.focus();
    }, 100);
  }

  submitCategoria(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present(
      this.mutateData.id ? "Alterando item..." : "Inserindo item..."
    );

    const newData = {
      ...new Categoria(),
      ...this.mutateData,
      idAvaliacao: this.avaliacao.id,
    };

    if (!this.mutateData.id) {
      this.categoriaService.post(newData).subscribe(
        (res: any) => {
          this.helper.openSnackBar("Item inserido com sucesso.");
          this.editable = false;
          this.buscarCategorias(this.avaliacao.id);
          this.mutateData = new Categoria();
          this.loadingService.dismiss();
        },
        (e) => {
          this.helper.openSnackBar(e.error);
          this.loadingService.dismiss();
        }
      );
    } else {
      this.categoriaService.patch(newData).subscribe(
        (res: any) => {
          this.helper.openSnackBar("Alteração de item");
          this.editable = false;
          this.buscarCategorias(this.avaliacao.id);
          this.mutateData = new Categoria();
          this.loadingService.dismiss();
        },
        (e) => {
          this.helper.openSnackBar(e.error);
          this.loadingService.dismiss();
        }
      );
    }
  }

  goBack() {
    this.router.navigate([
      "/avaliacoes/listagem",
      this.avaliacao.restaurante.id,
    ]);
  }

  alterarConfiguracao() {
    this.loadingService.present("Alterando alteração");
    this.avaliacaoService.patch(this.avaliacao).subscribe(
      (res: any) => {
        this.router
          .navigate(["/avaliacoes/listagem", this.avaliacao.restaurante.id])
          .then(() => {
            this.helper.openSnackBar("Configurações atualizadas com sucesso.");
            this.loadingService.dismiss();
          });
      },
      (e) => this.loadingService.dismiss()
    );
  }
}
